import { client } from "../index";
import { URL_CUSTOMERS } from "../apiconstant";

export const getCustomers = async () => {
  return await client.get(URL_CUSTOMERS);
};

export const createCustomer = async (data) => {
  return await client.post(URL_CUSTOMERS, data);
};

export const updateCustomer = async (customer_id, data) => {
  return await client.put(`${URL_CUSTOMERS}/${customer_id}`, data);
};

export const getCustomerDetails = async (customer_id) => {
  return await client.get(`${URL_CUSTOMERS}/${customer_id}`);
};

export const deleteCustomer = async (customer_id) => {
  return await client.delete(`${URL_CUSTOMERS}/${customer_id}`);
};
