import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Table from "react-bootstrap-table-next";

// rest
import { getOem } from "../../../rest/oem";
import { getDealerDetails, createDealer, updateDealer } from "../../../rest/dealer";

class DealerDetails extends React.Component { 

    state = {
        errMsg: "",
        scsMsg: "",
        oemData: [],
        oemId: []
    }

    constructor(props) {
        super(props);
        this.nameRef = React.createRef();
        this.addressRef = React.createRef();
        this.priContactRef = React.createRef();
        this.priPhoneRef = React.createRef();
        this.priEmailRef = React.createRef();
        this.secContactRef = React.createRef();
        this.secPhoneRef = React.createRef();
        this.secEmailRef = React.createRef();
    }

    async componentDidMount() {
        try {
            if(this.props.match.params.dealerId != 0){
                let res = await getDealerDetails(this.props.match.params.dealerId);
                this.setState({
                    oemId: [res.data.oemId]
                })
                this.nameRef.current.value = res.data.name;
                this.addressRef.current.value = res.data.address;
                this.priContactRef.current.value = res.data.primaryContact;
                this.priPhoneRef.current.value = res.data.primaryPhone;
                this.priEmailRef.current.value = res.data.primaryEmail;
                this.secContactRef.current.value = res.data.secondaryContact;
                this.secPhoneRef.current.value = res.data.secondaryPhone;
                this.secEmailRef.current.value = res.data.secondaryEmail;
            }
            this.getOemInfo();
        } catch (e) {
            console.log(e);
        }
    }

    getOemInfo = async()=>{
        try{
            let resOem = await getOem();
            this.setState({
                oemData: resOem.data
            })
        } catch (e) {
            console.log(e);
        }
    }

    oemSelected = (row, isSelect, idx, e) =>{
        this.setState({
            oemId: [row.id]
        })
    }

    navToList = () =>{
        this.props.history.push(`/admin/dealers`);
    }

    async postData() {
        try {
            this.setState({
                errMsg: "",
                scsMsg: ""
            })
            if(this.validate()){
                let data = {
                    name: this.nameRef.current.value,
                    address: this.addressRef.current.value,
                    primaryContact: this.priContactRef.current.value,
                    primaryPhone: this.priPhoneRef.current.value,
                    primaryEmail: this.priEmailRef.current.value,
                    secondaryContact: this.secContactRef.current.value,
                    secondaryPhone: this.secPhoneRef.current.value,
                    secondaryEmail: this.secEmailRef.current.value,
                    oemId: this.state.oemId[0]
                }
                if(this.props.match.params.dealerId != 0){
                    await updateDealer(this.props.match.params.dealerId, data);
                    this.setState({
                        scsMsg: "Dealer Updated Successfully"
                    })
                } else {
                    await createDealer(data);
                    this.setState({
                        scsMsg: "Dealer created Successfully"
                    }, () => {
                        this.reset();
                    })
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    validate = () =>{
        let eml = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let errMsg;
        if (this.nameRef && this.nameRef.current.value == "") {
            this.nameRef.current.focus();
            errMsg= "Please enter a valid dealer name";
        } else if (this.priEmailRef && this.priEmailRef.current.value != "" && !eml.test(this.priEmailRef.current.value)) {
            this.priEmailRef.current.focus();
            errMsg= "Please enter a valid primary email";
        } else if (this.secEmailRef && this.secEmailRef.current.value != "" && !eml.test(this.secEmailRef.current.value)) {
            this.secEmailRef.current.focus();
            errMsg= "Please enter a valid secondary email";
        } else if (this.state.oemId.length == 0) {
            errMsg= "Please select a oem";
        } 
        if(errMsg){
            this.setState({errMsg: errMsg});
            return false;
        }
        return true;
    }

    reset = () => {
        this.nameRef.current.value = "";
        this.addressRef.current.value = "";
        this.priContactRef.current.value = "";
        this.priPhoneRef.current.value = "";
        this.priEmailRef.current.value = "";
        this.secContactRef.current.value = "";
        this.secPhoneRef.current.value = "";
        this.secEmailRef.current.value = "";
    }

    render() {

        const tableColDef = [{
            dataField: 'id',
            text: 'OEM Id',
        },{
            dataField: 'name',
            text: 'Name',
        }];

        const selectRowCust = {
            mode: 'radio',
            classes: 'selection-row',
            selectColumnStyle: { width: '40px' },
            clickToSelect: true,
            selected: this.state.oemId,
            onSelect: this.oemSelected
        };

        return (
            <div className="width_100">
                <Card>
                    <CardHeader color="primary">
                        <h4><b>Dealer Info</b></h4>
                    </CardHeader>
                    <CardBody>
                        {((this.state.errMsg && this.state.errMsg !== "") || (this.state.scsMsg && this.state.scsMsg !== "")) ? (
                            <SnackbarContent
                                message={
                                    this.state.errMsg !== ""
                                        ? this.state.errMsg
                                        : this.state.scsMsg !== ""
                                            ? this.state.scsMsg
                                            : ""
                                }
                                color={
                                    this.state.errMsg !== ""
                                        ? "warning"
                                        : this.state.scsMsg !== ""
                                            ? "success"
                                            : ""
                                }
                            />
                        ) : (
                            ""
                        )}    
                        <GridContainer>
                            <GridItem sm={12} md={7}>
                                <p></p>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label txt-col-blk">Name</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" ref={this.nameRef}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label txt-col-blk">Address</label>
                                    <div className="col-sm-8">
                                        <textarea className="form-control" ref={this.addressRef} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label txt-col-blk">Primary Contact</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" ref={this.priContactRef}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label txt-col-blk">Primary Email</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" ref={this.priEmailRef}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label txt-col-blk">Primary Phone</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" ref={this.priPhoneRef}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label txt-col-blk">Secondary Contact</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" ref={this.secContactRef}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label txt-col-blk">Secondary Email</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" ref={this.secEmailRef}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label txt-col-blk">Secondary Phone</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" ref={this.secPhoneRef}/>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem sm={12} md={5}>
                                <p><b>Assigned to OEM</b></p>
                                {this.state.oemData.length > 0 ? 
                                <Table keyField="id"
                                    selectRow={selectRowCust}
                                    data={this.state.oemData}
                                    columns={tableColDef}
                                    noDataIndication="No Oems Available"/>
                                    : 
                                    <p className="ver-cen-content">No Oems Available</p>
                                  }
                            </GridItem>
                        </GridContainer>  
                        <GridContainer>
                            <GridItem sm={12} md={12}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="float_rght"
                                    color="primary"
                                    onClick={this.postData.bind(this)}
                                >
                                    {this.props.match.params.dealerId != 0 ? "Update": "Create"}
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="float_rght"
                                    onClick={this.navToList}
                                >
                                   Cancel
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>  
                </Card>
            </div>
        );
    }        
}

export default DealerDetails;