import React from "react";
import {Switch, Route} from 'react-router-dom';

// components
import ListOem from "./ListOem";
import OemDetails from "./OemDetails";

class Oems extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.path} render={(props) => <ListOem {...props}/>} />
        <Route path={`${this.props.match.path}/:oemId`}  render={(props) => <OemDetails {...props}/>} />
      </Switch>
    );
  }
}

export default Oems;