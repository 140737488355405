import { client, mwClient } from "../index";
import { URL_COMP_TAG } from "../apiconstant";

export const getTags = async (data) => {
    return await client.get(URL_COMP_TAG, { params: data });
};

export const postTags = async (data) => {
    return await client.post(URL_COMP_TAG, data);
};

export const updateTags = async (data) => {
    return await client.put(`${URL_COMP_TAG}/${data.id}`, data);
};

export const deleteTags = async (id) => {
    return await client.delete(`${URL_COMP_TAG}/${id}`);
};

export const invalidateTagsCache = async (id) => {
    return await mwClient.delete(`compressor/${id}/tags/cache`);
};
