import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "react-bootstrap-table-next";
import axios from "axios";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import moment from 'moment';
import { getCompressor } from "../../rest/compressor";
import { getTags } from "../../rest/tag";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { ExportCSV } from "./ExportToExcel";

import { Bar } from "react-chartjs-2";
import DateTimePicker from 'react-datetime-picker'
import TimePicker from 'react-bootstrap-time-picker';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
//Get Meta and Data URL from config file based on environment
import { config } from "../../config/config";

class Insights extends React.Component {

  state = {
    compData: {},
    tagData: [],
    sensors: [],
    selectedColumns: [],
    showReport: false,
    reportData: [],
    fromDate: "",
    offset: {},
    errMsg: "",
    exportToExcel: [],
    selectedOption: "",
    exportFileName: '',
    /*startDate: new Date(),
    endDate: new Date(),
	maxDate: new Date(),*/
    showChart: false,
    chartProperties: {
      labels: [],
	  options: {},
      datasets: [
        {
          label: '',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(153,54,40,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        }
      ]
    },
	startTime: "0:00",
	endTime: "23:00"
  }



  constructor(props) {
    super(props);
    this.compRef = React.createRef();
    /*this.fromDateRef = React.createRef();
    this.toDateRef = React.createRef();
    this.previousDate = new Date().setMonth(new Date().getMonth() - 1);*/
  }

  async componentDidMount() {

    this.loadCompressor();
  }

  componentDidUpdate() { }

  loadCompressor = async () => {
    try {
      let compRes = await getCompressor();
      let comData = compRes.data.filter(comp => {
        return JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].c != undefined ? JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].c.includes(comp.name) : JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].compressors.includes(comp.name);
      })
      this.setState({
        compData: comData
      })
    } catch (e) {
      console.log(e);
    }
  }

  loadTag = async (data) => {
    try {
      if (this.compRef.current.value != "") {
        let query = { filter: { "where": { "compressorId": this.compRef.current.value.split(",")[0], "showTrends": "true" } } };
        let tagRes = await getTags(query);
        this.setState({
          tagData: tagRes.data,
          sensors: [],
          selectedColumns: [],
          reportData: [],
          offset: {},
          showReport: false,
          columnDef: [],
          showChart: false,
          exportToExcel: '',
		  /*startDate: new Date(),
		  endDate: new Date(),
		  maxDate: new Date(),*/
		  showChart: false,
		  chartProperties: {
		    labels: [],
		    options: {},
		    datasets: [
			{
			  label: '',
			  fill: false,
			  lineTension: 0.1,
			  backgroundColor: 'rgba(153,54,40,0.6)',
			  borderColor: 'rgba(75,192,192,1)',
			  borderCapStyle: 'butt',
			  borderDash: [],
			  borderDashOffset: 0.0,
			  borderJoinStyle: 'miter',
			  pointBorderColor: 'rgba(75,192,192,1)',
			  pointBackgroundColor: '#fff',
			  pointBorderWidth: 1,
			  pointHoverRadius: 5,
			  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
			  pointHoverBorderColor: 'rgba(220,220,220,1)',
			  pointHoverBorderWidth: 2,
			  pointRadius: 1,
			  pointHitRadius: 10,
			  data: []
			}]
		  },
		  startTime: "0:00",
		  endTime: "23:00"
        });
        /*this.fromDateRef.current.value = "";
        if (this.fromDateRef.current.value != "") {
          this.toDateRef.current.value = "";
        }*/
      }
    } catch (e) {
      console.log(e);
    }
  }
  
  onChangeFromDate = startDate => this.setState({ startDate })
  
  onChangeToDate = endDate => this.setState({ endDate })
  
  handleStartTimeChange = startTime =>  this.setState({ startTime })
  
  handleEndTimeChange = endTime =>  this.setState({ endTime })
  
  handleEvent = (event, picker) => {
		let fromDate = moment(picker.startDate,'DD/MM/YYYY').format('MM-DD-YYYY');
		let toDate = moment(picker.endDate,'DD/MM/YYYY').format('MM-DD-YYYY');
		
		this.setState({
			startDate : fromDate,
			endDate : toDate
		});
	}
	
  dateFormatter = (cell, row) => {
    return (
      <span>{moment.unix(cell).format("MM/DD/YYYY")}</span>
    );
  }

  columnForamtter = (cell, row, idx, formatterData) => {

    return (
      <span>{(cell).toFixed(0)}</span>
    );

  }

  getReport = async (sensor, offset) => {
    try {
      this.setState({
        errMsg: "",
        startDate: this.state.startDate,
        /*endDate: this.state.endDate*/
      });

      if (this.validate()) {	
		let selectedStartHour = this.state.startTime == "0:00" ? this.state.startTime : ((this.state.startTime / 60) / 60) + ":00";
	    let selectedEndHour = this.state.endTime == "23:00" ? this.state.endTime : ((this.state.endTime / 60) / 60) + ":00";		
		let startDateWithTime = moment(this.state.startDate + " " + selectedStartHour, "MM/DD/YYYY HH:mm").unix();		
		let endDateWithTime = selectedEndHour != "23:00" ? moment(this.state.startDate + " " + selectedEndHour, "MM/DD/YYYY HH:mm").unix() : moment(this.state.startDate, "MM/DD/YYYY").add(1, 'days').unix();
		
		let pressureSensor = []
		this.items = this.state.tagData.map((item, key) =>			
			item.name.toString().toLowerCase().includes("pressure") ? pressureSensor.push(item.measureProperty) : ""			
		);
		
        let objPressure = {
          id: this.compRef.current.value.split(",")[1],
          start: startDateWithTime,
          end: endDateWithTime,
		  sensors: pressureSensor, 
		  sampling_unit: "hours" 	
        }
		
		let temperatureSensor = []
		this.items = this.state.tagData.map((item, key) =>			
			item.name.toString().toLowerCase().includes("temperature") ? temperatureSensor.push(item.measureProperty) : ""			
		);
		
        let objTemperature = {
          id: this.compRef.current.value.split(",")[1],
          start: startDateWithTime,
          end: endDateWithTime,
		  sensors: temperatureSensor, 
		  sampling_unit: "hours" 	
        }

		let energySensors = []
		this.items = this.state.tagData.map((item, key) =>			
			item.name.toString().toLowerCase().includes("energy") ? energySensors.push(item.measureProperty) : ""
		);
		
		let input = {
		  id: this.compRef.current.value.split(",")[1],
		  start: startDateWithTime,
		  end: endDateWithTime,				  
		  sensors: energySensors,
		  sampling_unit: "hours" 	
	    }

        var self = this;
		var labelsArray = [], tempDataArray = [], pressureDataArray = [], energyDataArray = [];
        axios
          //.post("https://exactspace.co/compressor/data", objPressure)
		  .post(config.DATA_API_URL + "compressor/data", objPressure)
          .then(function (response) {
            let columnDef = [];
            columnDef.push({
              dataField: "time",
              text: "Date",
              formatter: self.dateFormatter
            })			
            if (response.data != "") {
              Object.keys(response.data[0]).map((data, idx) => {
                if (data != 'time') {
                  columnDef.push({
                    dataField: data,
                    text: self.state.selectedColumns[(idx - 1)],
                    formatter: self.columnForamtter,
                    formatExtraData: {
                      offset: self.state.offset[data]
                    }
                  })
                }
              })
						  
              response.data.map((item, key) => {
                Object.keys(item).map(function (key) {
                  if (key == 'time') {
                    let dateVal
                    dateVal = moment.unix(item.time).format("HH:mm:ss");
                    labelsArray.push(dateVal);
                  }                  				  
			      else {
                    let itemValue = item[key];					
                    pressureDataArray.push(itemValue != null ? itemValue.toFixed(1): itemValue);
                  }				  				 
                });
              }
              );
			  
			  axios
			  //.post("https://exactspace.co/compressor/data", objTemperature)
			  .post(config.DATA_API_URL + "compressor/data", objTemperature)
			  .then(function (temperatureResponse) {				
				if (temperatureResponse.data) {					
					temperatureResponse.data.map((item, key) => {
						Object.keys(item).map(function (key) {							  
						  if(key != 'time') {
							let itemValue = item[key];					
							tempDataArray.push(itemValue != null ? itemValue.toFixed(1): itemValue);
						  }
						});
					});
				
				
					if(energySensors.length > 0){
					  axios
						  //.post("https://exactspace.co/compressor/reports", input)
						  .post(config.DATA_API_URL + "compressor/reports", input)
						  .then(function (energyResponse) {								
							if (energyResponse.data) {					
								energyResponse.data.map((item, key) => {
									Object.keys(item).map(function (key) {							  
									  if(key != 'time') {
										let itemValue = item[key];					
										energyDataArray.push(itemValue != null ? itemValue.toFixed(1) : itemValue);
									  }
									});
								});
								
								self.setState({
									chartProperties: {
									  labels: labelsArray,
									  options : {
										  responsive: true,
										  tooltips: {
											mode: 'label'
										  },
										  elements: {
											line: {
											  fill: false
											}
										  },
										  scales: {
											xAxes: [
											  {
												display: true,
												/*gridLines: {
												  display: false
												},
												labels: {
												  show: true
												}*/
											  }
											],
											yAxes: [
											  {
												type: 'linear',
												display: true,
												position: 'left',
												id: 'y-axis-1',
												gridLines: {
												  display: false
												},
												labels: {
												  show: true
												},
												ticks: {
												  beginAtZero: true,
												  min: 0
												} 
											  },
											  {
												type: 'linear',
												display: true,
												position: 'right',
												id: 'y-axis-2',
												gridLines: {
												  display: false
												},
												labels: {
												  show: true
												},
												/*ticks: {
												  beginAtZero: true,
												  min: 0
												} */
											  }
											]
										  }
									  },
									  datasets: [
										{
										  type: 'bar',		
										  label: 'Avg. Temp. (Deg C)', 
										  data: tempDataArray,
										  backgroundColor: 'rgba(249, 105, 14, 1)', /*'rgba(255,99,132,0.4)',*/
										  yAxisID: 'y-axis-1',
										  order: 1
										},
										{
										  type: 'bar',		
										  label: 'Energy Consumption (kWH)', 
										  data: energyDataArray,
										  backgroundColor: 'rgba(169,169,169)',			
										  yAxisID: 'y-axis-1',
										  order: 2
										},
										{
										  type: 'line',		
										  label: 'Avg. Pressure (Bar)', 
										  data: pressureDataArray,
										  backgroundColor: 'rgba(0,0,255,0.9)',
										  fill: false,
										  yAxisID: 'y-axis-2',
										  order: 3
										}]				 
									}
								  })
																				
								var pressureArray = [];
								pressureArray = response.data;
								if(tempDataArray.length > 0) {							
									for(let i = 0; i < pressureArray.length; i++) {							
										pressureArray[i]["Avg. Temp. (Deg C)"] = tempDataArray[i];
									}
								}
								if(energyDataArray.length > 0) {							
									for(let i = 0; i < pressureArray.length; i++) {							
										pressureArray[i]["Energy Consumption (kWH)"] = energyDataArray[i];
									}
								}						
								
								self.setState({
								  columnDef: columnDef,
								  showReport: true,
								  exportToExcel: pressureArray
								})
							}
						})
						.catch(function (error) {
						  console.log(error);
						});
					}
					else {
						self.setState({
							chartProperties: {
							  labels: labelsArray,
							  options : {
								  responsive: true,
								  tooltips: {
									mode: 'label'
								  },
								  elements: {
									line: {
									  fill: false
									}
								  },
								  scales: {
									xAxes: [
									  {
										display: true,
										/*gridLines: {
										  display: false
										},
										labels: {
										  show: true
										}*/
									  }
									],
									yAxes: [
									  {
										type: 'linear',
										display: true,
										position: 'left',
										id: 'y-axis-1',
										gridLines: {
										  display: false
										},
										labels: {
										  show: true
										},
										ticks: {
										  beginAtZero: true,
										  min: 0
										} 
									  },
									  {
										type: 'linear',
										display: true,
										position: 'right',
										id: 'y-axis-2',
										gridLines: {
										  display: false
										},
										labels: {
										  show: true
										},
										/*ticks: {
										  beginAtZero: true,
										  min: 0
										} */
									  }
									]
								  }
							  },
							  datasets: [
								{
								  type: 'bar',		
								  label: 'Avg. Temp. (Deg C)', 
								  data: tempDataArray,
								  backgroundColor: 'rgba(249, 105, 14, 1)', /*'rgba(255,99,132,0.4)',*/
								  yAxisID: 'y-axis-1',
								  order: 0
								},								
								{
								  type: 'line',		
								  label: 'Avg. Pressure (Bar)', 
								  data: pressureDataArray,
								  backgroundColor: 'rgba(0,0,255,0.9)',
								  fill: false,
								  yAxisID: 'y-axis-2',
								  order: 1
								}]				 
							}
						  })
						
						var pressureArray = [];
						pressureArray = response.data;
						if(tempDataArray.length > 0) {							
							for(let i = 0; i < pressureArray.length; i++) {							
								pressureArray[i]["Avg. Temp. (Deg C)"] = tempDataArray[i];
							}
						}
								
						self.setState({
						  columnDef: columnDef,
						  showReport: true,
						  exportToExcel: pressureArray
						})
					}						
				}
			    })
				.catch(function (error) {
				  console.log(error);
				});
            }
			else {
				let errMsg = "";
				errMsg = "No Data to Display";
				if (errMsg != "") {
					self.setState({ 
						errMsg: errMsg,
						exportToExcel: '',		 
					    showChart: false,
					    chartProperties: {
							labels: [],
							options: {},
							datasets: [
							{
							  label: '',
							  fill: false,
							  lineTension: 0.1,
							  backgroundColor: 'rgba(153,54,40,0.6)',
							  borderColor: 'rgba(75,192,192,1)',
							  borderCapStyle: 'butt',
							  borderDash: [],
							  borderDashOffset: 0.0,
							  borderJoinStyle: 'miter',
							  pointBorderColor: 'rgba(75,192,192,1)',
							  pointBackgroundColor: '#fff',
							  pointBorderWidth: 1,
							  pointHoverRadius: 5,
							  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
							  pointHoverBorderColor: 'rgba(220,220,220,1)',
							  pointHoverBorderWidth: 2,
							  pointRadius: 1,
							  pointHitRadius: 10,
							  data: []
							}]
						  },
					    startTime: "0:00",
					    endTime: "23:00"
					});
					return false;
				}				
			}										     
          })
          .catch(function (error) {
			//alert(error);
            console.log(error);
          });
      }
	  else {
		  this.loadTag();
	  }
    } catch (e) {
      console.log(e);
    }
  }

  validate = () => {
    let errMsg = "";
    if (this.compRef.current.value == "") {
      errMsg = "Please select a compressor";
    } else if (this.state.startDate == undefined) {
      errMsg = "Please select the Date";
    } 
	/*else if (this.state.endDate == "") {
      errMsg = "Please select a To Date";
    } else if (this.state.endDate < this.state.startDate) {
	  errMsg = "To Date should not be less than From Date";	
	}*/
	else if (this.state.startTime == this.state.endTime) {
	  errMsg = "Start and End Time should not be same";	
	} else if (this.state.startTime > this.state.endTime) {
	  errMsg = "End Time should not be less than Start Time";	
	}
    if (errMsg != "") {
      this.setState({ errMsg: errMsg });
      return false;
    }
    return true;
  }

  tagHandler = (row) => {
    let chartlabels = `${row.name} (${row.measureUnits})`;
    this.setState({
      selectedColumns: [chartlabels],
      chartProperties: {
        datasets: [
          {
            label: row.name
          }]
      }
    })
    this.getReport(row.measureProperty, row.offset);
  }

  render() {
    const tableColDef = [{
      dataField: 'name',
      text: 'Tags'
    }];

    const selectRowTag = {
      mode: 'radio',
      classes: 'form-check-input',
      selectColumnStyle: { width: '40px' },
      selected: this.state.selectedColumns,
      onSelect: this.tagHandler.bind(this),
      clickToSelect: true,
      hideSelectAll: true
    };

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <div style={{ "width": "10%", "float": "left" }}> <h4>Insights</h4></div>
                {this.state.showReport && <div style={{ "width": "10%", "float": "right" }}><ExportCSV csvData={this.state.exportToExcel} fileName={'Insights_' + moment(this.state.startDate, "YYYY-MM-DD H:mm") + '_' + moment(this.state.endDate, "YYYY-MM-DD H:mm")} /></div>}
              </CardHeader>
              <CardBody>
                {((this.state.errMsg && this.state.errMsg !== "")) ? (
                  <SnackbarContent
                    message={
                      this.state.errMsg !== ""
                        ? this.state.errMsg
                        : ""
                    }
                    color={
                      this.state.errMsg !== ""
                        ? "warning"
                        : ""
                    }
                  />
                ) : (
                    ""
                  )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {(this.state.compData.length > 0) ?


                      <div style={{ "width": "100%" }}>
                        <div style={{ "width": "150px", "float": "left", "marginRight": "10px" }}>
                          <div>Select Compressors</div>
                          <div>
                            <select className="custom-select" onChange={this.loadTag} ref={this.compRef}>
                              <option value="">Select</option>
                              {
                                this.state.compData.map((data) => {
                                  return <option value={data.id + "," + data.name}>{data.name}</option>
                                })
                              }
                            </select>
                          </div>
                        </div>                        
						<div style={{ "float": "left", "marginRight": "20px" }}>
							<div>Select Date</div>
							<DateRangePicker dateFormat="MM/DD/YYYY" startDate={this.state.startDate ? this.state.startDate : new Date()} endDate={this.state.endDate ? this.state.endDate : new Date()} singleDatePicker={true} onEvent = {this.handleEvent}>								
								<Button style={{ "width":"150px"}} type="button" class="btn btn-outline-info"><i style={{ "float": "right", "marginBottom": "-4px" }} className="fa fa-calendar"/><span>{this.state.startDate}</span></Button>
							</DateRangePicker>
					    </div>
                        <div style={{ "float": "left", "marginRight": "20px" }}>
                            <div>Start Time</div>                            							
							<TimePicker start="00:00" end="23:00" format={24} step={60} onChange={this.handleStartTimeChange} value={this.state.startTime} />							
						</div>	
						<div style={{ "float": "left", "marginRight": "20px" }}>
                            <div>End Time</div>                            							
							<TimePicker start="00:00" end="23:00" format={24} step={60} onChange={this.handleEndTimeChange} value={this.state.endTime} />							
						</div>		
					    
						<div style={{ "width": "220px", "float": "left", "marginRight": "20px" }}>
							<div style={{ "marginBottom": "-10px" }}>&nbsp;</div>
							<Button type="button" color="primary" variant="contained" onClick={this.getReport} className="text-right">		
								Show Insights
							</Button>	
						</div>
                        <br />
                        

                      </div> :
                      <div>
                        no data
					</div>}

                  </GridItem>
                  <GridItem sm={12} md={12}>

                    <Bar width={300} height={100} data={this.state.chartProperties} options={this.state.chartProperties.options} />

                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default Insights;
