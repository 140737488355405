// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Report from "@material-ui/icons/Report";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import TrendingUpRounded from "@material-ui/icons/TrendingUpRounded"
import map from "@material-ui/icons/Map";
import info from "@material-ui/icons/Info";
import profile from "@material-ui/icons/VerifiedUser";
import user from "@material-ui/icons/SupervisedUserCircle";
import BarChart from '@material-ui/icons/BarChart';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import Login from "views/Login/Login.jsx";
import Reports from "views/Reports/Reports.jsx";
import Trends from "views/Trends/Trends.jsx";
import Maps from "views/Maps/Maps.jsx";
import Ingest from "views/Ingest/Ingest.jsx";
import NotificationsPage from "views/Notifications/Notifications.jsx";
import Users from "views/UserProfile/component/Users";
import Tags from "views/Tags/components/Tags.jsx";
import Oems from "views/Oems/components/Oems";
import Dealers from "views/Dealer/components/Dealers";
import Customers from "views/Customers/components/Customers";
import Compressors from "views/Compressor/components/Compressor";
import Insights from "views/Insights/Insights.jsx";
import UpdateCompressors from "views/UpdateCompressor/components/Compressor";
import SetThreshold from "views/SetThreshold/components/Tags.jsx";

let data = JSON.parse(window.localStorage.getItem("profileInfo"));

const dashboardRoutes = [
  {
    path: "/dashboard",
    menuName: "Dashboard",
    name: "Assets Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },{
    path: "/reports",
    menuName: "Reports",
    icon: Report,
    component: Reports,
    layout: "/admin"
  },{
    path: "/trends",
    menuName: "Trends",
    icon: TrendingUpRounded,
    component: Trends,
    layout: "/admin"
  },{
    path: "/insights",
    menuName: "Insights",
    icon: BarChart,
    component: Insights,
    layout: "/admin"
  }
];

if (data && (data.accessLevel === 'Super Admin' || data.accessLevel === 'Admin')) {
  dashboardRoutes.push({
    path: "/updatecompressors",
    menuName: "Update Assets",
    icon: profile,
    component: UpdateCompressors,
    layout: "/admin"
  });
}	

if (data && data.accessLevel === 'Super Admin') {

  dashboardRoutes.push({
    path: "/SetThreshold",
    menuName: "Set Threshold",
    icon: profile,
    component: SetThreshold,
    layout: "/admin"
  },{
    path: "/notification",
    menuName: "Notification",
    icon: NotificationImportant,
    component: NotificationsPage,
    layout: "/admin"
  },{
    path: "/maps",
    menuName: "Maps",
    icon: map,
    component: Maps,
    layout: "/admin"
  },{
    path: "/ingest",
    menuName: "Ingest",
    icon: info,
    component: Ingest,
    layout: "/admin"
  },{
    path: "/users",
    menuName: "Users",
    icon: user,
    component: Users,
    layout: "/admin"
  },{
    path: "/tags",
    menuName: "Tags",
    icon: profile,
    component: Tags,
    layout: "/admin"
  },{
    path: "/oems",
    menuName: "Oems",
    icon: profile,
    component: Oems,
    layout: "/admin"
  },{
    path: "/dealers",
    menuName: "Dealers",
    icon: profile,
    component: Dealers,
    layout: "/admin"
  },{
    path: "/customers",
    menuName: "Customers",
    icon: profile,
    component: Customers,
    layout: "/admin"
  },{
    path: "/compressors",
    menuName: "Compressor",
    icon: profile,
    component: Compressors,
    layout: "/admin"
  });
}  

dashboardRoutes.push({
  path: "/login",
  name: "hidden",
  icon: "content_paste",
  component: Login,
  layout: "/login"
});

export default dashboardRoutes;
