import React from "react";
import {Switch, Route} from 'react-router-dom';

// components
import ListUsers from "./ListUsers";

class Users extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.path} render={(props) => <ListUsers {...props}/>} />
      </Switch>
    );
  }
}

export default Users;