import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Table from "react-bootstrap-table-next";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from "react-bootstrap/Modal";

// rest
import { getOem } from "../../../rest/oem";
import { getCustomers } from "../../../rest/customer";
import { getCompressorDetails, createCompressor, updateCompressor, updateCompressors } from "../../../rest/compressor";

//Get Meta and Data URL from config file based on environment
import { config } from "../../../config/config";
import axios from "axios";

class CustomerDetails extends React.Component {

    state = {
        errMsg: "",
        scsMsg: "",
        oemData: [],
        customerData: [],
        oemId: [],
        customerId: [],
        severity: [],
        codes: {},
        key: "compressor_info",
        sevSelected: "",
        action: "severity",
        showModal: false,
        mdlIdx: 0,			    
    }

    constructor(props) {
        super(props);
        this.nameRef = React.createRef();
        this.compressorNameRef = React.createRef();   
        this.mobileNumberRef = React.createRef();     
        this.fabNoRef = React.createRef();        
        this.locationRef = React.createRef();
        this.siteNameRef = React.createRef();
        this.latRef = React.createRef();
        this.longRef = React.createRef();        
    }
    

    async componentDidMount() {
        try {			
			let currentBinaryValue = false;
            if (this.props.match.params.compressorId != 0) {
                let res = await getCompressorDetails(this.props.match.params.compressorId);
                this.setState({
                    oemId: [res.data.oemId],
                    customerId: [res.data.customerId]
                })
                this.nameRef.current.value = res.data.name || "";
                this.compressorNameRef.current.value = res.data.compressorName || "";
                this.mobileNumberRef.current.value = res.data.phone || "";
                this.fabNoRef.current.value = res.data.fabNo || "";                                
                this.locationRef.current.value = res.data.location || "";
                this.siteNameRef.current.value = res.data.site_name || "";
                this.latRef.current.value = res.data.lat || "";
                this.longRef.current.value = res.data.long || "";				
            }
            this.getOemInfo();
            this.getCustomerInfo();
        } catch (e) {
            console.log(e);
        }
    }    

    getCustomerInfo = async () => {
        try {
            let resCustomer = await getCustomers();
            this.setState({
                customerData: resCustomer.data
            })
        } catch (e) {
            console.log(e);
        }
    }

    oemSelected = (row, isSelect, idx, e) => {
        this.setState({
            oemId: [row.id],
            customerId: []
        })
    }

    customerSelected = (row, isSelect, idx, e) => {
        this.setState({
            customerId: [row.id],
            oemId: []
        })
    }

    navToList = () => {
        this.props.history.push(`/admin/updatecompressors`);
    }

    postData = async () => {
		
        try {
            this.setState({
                errMsg: "",
                scsMsg: ""
            })
			
            if (this.validate()) {
                let data = {
                    name: this.nameRef.current.value,
                    compressorName: this.compressorNameRef.current.value,
                    phone: this.mobileNumberRef.current.value,
                    fabNo: this.fabNoRef.current.value,                    
                    location: this.locationRef.current.value,
                    site_name: this.siteNameRef.current.value,
                    oemId: this.state.oemId[0],
                    //customerId: this.state.customerId[0],
                    lat: this.latRef.current.value,
                    long: this.longRef.current.value
                }
				//debugger;							
                if (this.props.match.params.compressorId != 0) {
					let query = "where={" + "\"id\"" + ":\"" + this.props.match.params.compressorId + "\"}";
					let updateCompressorURL = config.META_API_URL +  `compressors/update?` + query; 
					await axios
						.post(updateCompressorURL, data)
						.then((response) => {
							console.log(response.data);	
						}).catch(function (error) {          
						  console.log(error);
						});;					                    
                    this.setState({
                        scsMsg: "Compressor Updated Successfully"
                    })
                } else {
                    await createCompressor(data);
                    this.setState({
                        scsMsg: "Compressor created Successfully"
                    }, () => {
                        this.reset();
                    })
                }
            }
        } catch (e) {
            console.log(e);
            if (e.response) {
                this.setState({ errMsg: e.response.data.error.message });
            }
        }
    }

    validate = () => {
        let eml = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let errMsg;
        if (this.nameRef && this.nameRef.current.value == "") {
            this.nameRef.current.focus();
            errMsg = "Please enter a valid dealer name";
        } else if (this.state.customerId.length == 0 && this.state.oemId.length == 0) {
            errMsg = "Please select a oem / a customer";
        }
        if (errMsg) {
            this.setState({ errMsg: errMsg });
            return false;
        }
        return true;
    }

    validateModal = (action) => {
		if (action != "severity") {
			if (this.modalNameRef && this.modalNameRef.current.value == "") {
				this.modalNameRef.current.focus();
				alert("please enter a valid value");
				return false;
			} 
		}
		if (this.modelValueRef && this.modelValueRef.current.value == "") {
            this.modelValueRef.current.focus();
            alert("please enter a valid value");
            return false;
        };
        return true;
    }

    reset = () => {
        this.nameRef.current.value = "";
        this.compressorNameRef.current.value = "";   
        this.mobileNumberRef.current.value = "";    
        this.fabNoRef.current.value = "";        
        this.locationRef.current.value = "";
        this.siteNameRef.current.value = "";
        this.latRef.current.value = "";
        this.longRef.current.value = "";
    }
	
    
    render() {

        return (
            <div className="width_100">
                <Card>
                    <CardHeader color="primary">
                        <h4><b>Compressor Info</b></h4>
                    </CardHeader>
                    <CardBody>
                        {((this.state.errMsg && this.state.errMsg !== "") || (this.state.scsMsg && this.state.scsMsg !== "")) ? (
                            <SnackbarContent
                                message={
                                    this.state.errMsg !== ""
                                        ? this.state.errMsg
                                        : this.state.scsMsg !== ""
                                            ? this.state.scsMsg
                                            : ""
                                }
                                color={
                                    this.state.errMsg !== ""
                                        ? "warning"
                                        : this.state.scsMsg !== ""
                                            ? "success"
                                            : ""
                                }
                            />
                        ) : (
                                ""
                            )}
                        <Tabs id="controlled-tab-example" activeKey={this.state.key} onSelect={k => this.setState({ key: k })}>
                            <Tab eventKey="compressor_info" title="Compressor Details">
                                <div style={{padding:'10px'}}>
                                <GridContainer>
                                    <GridItem sm={12} md={7}>
                                        <p></p>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Name</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.nameRef} readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Compressor name</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.compressorNameRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Phone</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.mobileNumberRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Fab No.</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.fabNoRef} />
                                            </div>
                                        </div>                                        
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Location</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.locationRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Site Name</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.siteNameRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Geo Coordinates</label>
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" ref={this.latRef} placeholder="Lat" />
                                            </div>
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" ref={this.longRef} placeholder="long" />
                                            </div>
                                        </div>										
                                    </GridItem>                                    
                                </GridContainer>
                                </div>                        
                            </Tab>                            
                        </Tabs>
                        <GridContainer>
                            <GridItem sm={12} md={12}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="float_rght"
                                    color="primary"
                                    onClick={this.postData.bind(this)}
                                >
                                    {this.props.match.params.compressorId != 0 ? "Update" : "Create"}
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="float_rght"
                                    onClick={this.navToList}
                                >
                                    Cancel
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>							
            </div>
        );
    }
}

export default CustomerDetails;