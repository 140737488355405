export const URL_LOGIN = "users/login";
export const URL_LOGOUT = "users/logout";
export const URL_SIGNUP = "users";

// User
export const URL_USER = "users";
export const URL_USERPROFILE = "userprofiles";

//Oem
export const URL_OEM = "oems";

//dealer
export const URL_DEALER = "dealers";

//customer
export const URL_CUSTOMERS = "customers";

//Compressor
export const URL_COMPRESSOR = "compressors";

// Tags 
export const URL_COMP_TAG = "tags";
