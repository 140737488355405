import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 11.030600, lng: 76.958570 }} />
	  <Marker position={{ lat: 10.441360, lng: 78.094620 }} />
	  <Marker position={{ lat: 10.062680, lng: 78.286680 }} />
	  <Marker position={{ lat: 11.074820, lng: 77.002160 }} />
	  <Marker position={{ lat: 10.786870, lng: 77.174290 }} />
	  
	  <Marker position={{ lat: 11.046260, lng: 76.948340 }} />
	  <Marker position={{ lat: 11.043620, lng: 76.924670 }} />
	  <Marker position={{ lat: 11.001470, lng: 77.008530 }} />
	  <Marker position={{ lat: 10.914160, lng: 76.948750 }} />
	  <Marker position={{ lat: 11.047470, lng: 76.920830 }} />
	  
	  <Marker position={{ lat: 11.334820, lng: 77.727280 }} />
	  <Marker position={{ lat: 11.028250, lng: 76.968320 }} />
	  <Marker position={{ lat: 11.012683, lng: 76.989487 }} />
	  <Marker position={{ lat: 11.012683, lng: 76.989487 }} />
	  <Marker position={{ lat: 11.045340, lng: 77.032680 }} />
    </GoogleMap>
  ))
);

function Maps() {
  return (
    <CustomSkinMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCdkki6MY_WY2KAj0UDlXINDlHOZPadSnU"
      loadingElement={<div style={{ height: "95%" }} />}
      containerElement={<div style={{ height: "90vh" }} />}
      mapElement={<div style={{ height: "100%" }} />}
    />
  );
}

export default Maps;
