import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import axios from "axios";
import moment from "moment";

import DashCards from "../dashcards/dashcards.jsx";

// rest
import { getCompressor } from "../../rest/compressor";
import { getTags } from "../../rest/tag";

import "./css/dashboard.css";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

//Get Meta and Data URL from config file based on environment
import { config } from "../../config/config";

class Dashboard extends React.Component {
  state = {
    profileInfo: JSON.parse(window.localStorage.getItem("profileInfo")),
    hideCompressorInfo: false,
    active: 0,
    inactive: 0,
    servicedue: 0,
    fault: 0,
    name: "",
    asset: "",
    showAllTag: false,
    site_name: "",
    currentFault: "",
    currentCompData: "",
	selectedType: "all",
	selectedAsset: ""
  }
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  async componentDidMount() {
    await this.getCompDetails();
    if (this.state.compdetails && this.state.compdetails.length > 0) {
      await this.getCompData(null, this.state.compdetails[0]);
    }
	if(this.state.currentCompData != "") {				
		/*this.interval1 = await setInterval(() => {this.getCompDetails()},60000);*/
		this.interval = await setInterval(() => {this.filterCompByCard(this.state.selectedType)},60000);
		/*this.interval2 = await setInterval(() => {this.getCompData(null, this.state.currentCompData)},60000);*/
	}
  }

  filterCompByCard = async (type) => {
    try {
	  var previousSelectedType = this.state.selectedType;	  
      let compData = [...this.state.actualData];
      let filterData;
      switch (type) {
        case "all":
          filterData = compData;
          break;
        case "active":
          filterData = compData.filter(data => {
            if (data.active) {
              return true;
            }
            return false;
          });
          break;
        case "inactive":
          filterData = compData.filter(data => {
            if (!data.active) {
              return true;
            }
            return false;
          });
          break;
        case "servicedue":
          filterData = compData.filter(data => {
            if (data.service_due) {
              return true;
            }
            return false;
          });
          break;
        case "fault":
          filterData = compData.filter(data => {
            if (data.fault_reported) {
              return true;
            }
            return false;
          });
          break;
      }

      this.setState({
        compdetails: filterData,
        hideCompressorInfo: true,
        name: "",
		selectedType: type
      }, async () => {
        if (this.state.compdetails && this.state.compdetails.length > 0) {
          await this.getCompData(null, this.state.currentCompData != "" && previousSelectedType == type ? this.state.currentCompData : this.state.compdetails[0]);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() { clearInterval(this.interval); /*clearInterval(this.interval1); clearInterval(this.interval2);*/ }

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
	const bandA = a.name.toUpperCase();
	const bandB = b.name.toUpperCase();

	let comparison = 0;
	if (bandA < bandB) {
		comparison = 1;
	} else if (bandA > bandB) {
		comparison = -1;
	}
	return comparison;
  }
	
  getCompDetails = async () => {
    try {
	  //debugger;
      let query = { filter: { "where": { "customerId": this.state.profileInfo.customerId } } };
      let data = await getCompressor();

      let compData = data.data.filter((cur) => {
        return this.state.profileInfo.accessItems[0].c != undefined ? this.state.profileInfo.accessItems[0].c.includes(cur.name) : this.state.profileInfo.accessItems[0].compressors.includes(cur.name);
      }, this);
	  compData.sort(this.compare);
      let inactive = 0;
      let servicedue = 0;
      let fault = 0;

      compData.forEach((data) => {
        if (!data.active) {
          inactive += 1;
        }
        if (data.service_due) {
          servicedue += 1;
        }
        if (data.fault_reported) {
          fault += 1;
        }
      });

      this.setState({
        compdetails: compData,
        actualData: compData,
        active: compData.length - (inactive + servicedue + fault),
        inactive: inactive,
        fault: fault,
        servicedue: servicedue
      });
    } catch (error) {
      console.log(error);
    }
  }

  getCompTagDetails = async (id) => {
    var self = this;
    try {
      let query = { filter: { "where": { "compressorId": id } } };
      let tagData = await getTags(query);
      let tags = [];
      tagData.data.map((tag) => {
        tags.push(tag.measureProperty)
      });
      self.setState({
        tagDetails: tagData.data,
        tags: tags
      });
    } catch (error) {
      console.log(error);
    }
  }

  getCompData = async (e, data) => {
    var self = this;
    try {	  
      if (e) {
        e.preventDefault();
      }	  
      await this.getCompTagDetails(data.id);

      // catch up the fault datas.
      if (data.fault_reported) {
		//let faultPath = `https://dev.exactspace.co/utvapi/faults?filter[where][compressorName]=${data.name}`;
        let faultPath = config.META_API_URL +  `faults?filter[where][compressorName]=${data.name}`;
        await axios
          .get(faultPath)
          .then((response) => {
            if (response.data.length > 0) {
              self.setState({
                currentFault: response.data.pop(),
                currentCompData: data
              });
            }
          });
      } else {
        self.setState({
          currentFault: "",
          currentCompData: data
        })
      }

	const headers = {
	  'Content-Type': 'application/json',
	  'Access-Control-Allow-Headers': 'access-control-allow-origin, content-type',
	  'Access-Control-Allow-Methods': 'DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT'
	}

      axios
	    //.post("https://exactspace.co/compressor/data", {
        .post(config.DATA_API_URL + "compressor/data", {
          id: data.name,
          sensors: this.state.tags
        }, { headers: headers })
        .then(function (response) {
          if (response.data !== "") {
            let compressorData = response.data;
            if (compressorData) {
              self.setState({
                hideCompressorInfo: false,
                compressorData: compressorData,
                id: data.name,
                name: data.site_name,
                asset: data.compressorName,
                site_name: data.location
              });
            }
          } else {
            self.setState({
              hideCompressorInfo: true,
              name: data.site_name,
              asset: data.compressorName,
              site_name: data.location
            })
          }
        })
        .catch(function (error) {
          self.setState({
            hideCompressorInfo: true
          })
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  toggleDisplayTag = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      showAllTag: !this.state.showAllTag
    })
  }

  render() {
    if (this.state.compdetails == undefined) return false;
    const { classes } = this.props;

    return (
      <div>
        <DashCards filterCompData={this.filterCompByCard} classes={classes} details={this.state}></DashCards>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} class="scrollBar">
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Asset Statistics</h4>
              </CardHeader>
              <CardBody>
                {(this.state.compdetails && this.state.compdetails.length > 0) ? (
                  <table className="customTable">
                    <thead className="thead">
                      <tr>
                        <th style={{"width":"10%"}}>ID</th>
                        <th style={{"width":"10%"}}>ASSET</th>
                        <th style={{"width":"10%"}}>FAB#</th>
                        <th style={{"width":"10%"}}>SITE</th>
                        <th style={{"width":"10%"}}>LOCATION</th>
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      {
                        this.state.compdetails.map((data) => {
                          return (
                            <tr>
                              <td style={{"width":"10%"}}><span onClick={(e) => { this.getCompData(e, data) }} className="hyperlink">{data.name}</span></td>
                              <td style={{"width":"10%"}}>{data.compressorName}</td>
                              <td style={{"width":"10%"}}>{data.fabNo}</td>
                              <td style={{"width":"10%"}}>{data.site_name}</td>
                              <td style={{"width":"10%"}}>{data.location}</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>) : (<p className="text-center">No Data Available</p>)
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
