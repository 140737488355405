import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "react-bootstrap-table-next";
import axios from "axios";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import TimePicker from 'react-bootstrap-time-picker';

import moment from 'moment';
import { getCompressor } from "../../rest/compressor";
import { getTags } from "../../rest/tag";

import { ExportCSV } from "./ExportToExcel";
import paginationFactory from 'react-bootstrap-table2-paginator';
import CheckBox from './CheckBox'
//Get Meta and Data URL from config file based on environment
import { config } from "../../config/config";

class Reports extends React.Component {

  state = {
    compData: {},
    tagData: [],
    sensors: [],
    selectedColumns: [],
    showReport: false,
    reportData: [],
    fromDate: "",
    offset: {},
    errMsg: "",
    exportToExcel: [],
    reportsModel: [],
    sensorNames: [],
    reportsUrl: '',
    currentComp: {},
    faultUrl: '',
	startTime: "0:00",
	endTime: "23:00"
	
  }


  handleClick = async (event) => {
    var checked = event.target.checked;
    this.state.reportsModel[event.target.value].checked = checked;
  }

  constructor(props) {
    super(props);
    this.compRef = React.createRef();
    // this.fromDateRef = React.createRef();
    // this.toDateRef = React.createRef();
    this.previousDate = new Date().setMonth(new Date().getMonth() - 1);
	this.handleEvent = this.handleEvent.bind(this);
	this.generateReport = this.generateReport.bind(this);
	this.setState({
		selectLabel : "",
		maxDate : '',
		faultCodeDefined : true
	});
  }

  async componentDidMount() {
    this.loadCompressor();
  }

  componentDidUpdate() { }

  change = async (event) => {
    this.setState({
      reportsModel: [],
      sampleUnitValue: event.target.value,
	  faultCodeDefined : true
    })

    let report = {
      "runHours": "showRunHours",
      "energy": "showEnergy",
      "critical": "showCritical",
      /*"runHoursUrl": "https://exactspace.co/compressor/reports",
      "energyUrl": "https://exactspace.co/compressor/reports/energyconsumption",
      "criticalUrl": "https://exactspace.co/compressor/data",
      "faultUrl": "https://dev.exactspace.co/utvapi/faults"*/
	  "runHoursUrl": config.DATA_API_URL + "compressor/reports",
      "energyUrl": config.DATA_API_URL + "compressor/reports/energyconsumption",
      "criticalUrl": config.DATA_API_URL + "compressor/data",
      "faultUrl": config.META_API_URL + "faults"
    }


    let reportQuery = report[event.target.value], reportApiUrl = report[event.target.value + "Url"];
    let query = {
      filter: {
        "where": {
          "compressorId": (event.target.value != 'fault') ? this.compRef.current.value.split(",")[0] : this.compRef.current.value.split(",")[1]
        }
      }
    };

    query.filter.where[reportQuery] = true;
    let tagRes = await getTags(query);
    this.setState({
      reportsModel: tagRes.data,
      reportsUrl: reportApiUrl,
      reportQuery: reportQuery,
      showReport: false,
      fromDate: "",
      reportData: []
    }, () => {
      // this.fromDateRef.current.value = "";
    /*  if(this.toDateRef && this.toDateRef.current != null){
        this.toDateRef.current.value = "";
      }  */
    });
  }
  
  handleEvent = (event, picker) => {
		let fromDate = moment(picker.startDate,'DD/MM/YYYY').format('MM-DD-YYYY');
		let toDate = moment(picker.endDate,'DD/MM/YYYY').format('MM-DD-YYYY');
		let maxDate = moment(picker.startDate,'DD/MM/YYYY').add('days', 30).format('MM-DD-YYYY');
		
		this.setState({
			startDate : fromDate,
			endDate : toDate,
			maxDate : maxDate,
			selectLabel : ""
		});
    }
	
  handleStartTimeChange = startTime =>  this.setState({ startTime })
  
  handleEndTimeChange = endTime =>  this.setState({ endTime })

  loadCompressor = async () => {
    try {
      let compRes = await getCompressor();
      let comData = compRes.data.filter(comp => {
        return JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].c != undefined ? JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].c.includes(comp.name) : JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].compressors.includes(comp.name);
      })
      this.setState({
        compData: comData,
		faultCodeDefined : true
      })
    } catch (e) {
      console.log(e);
    }
  }

  loadTag = async (data) => {
    try {
      if (this.compRef.current.value != "") {
        let query = { filter: { "where": { "compressorId": this.compRef.current.value.split(",")[0], "showReport": "true" } } };
        let compData = this.state.compData.find(c => c.name == this.compRef.current.value.split(",")[1]);
        let tagRes = await getTags(query);
        this.setState({
          tagData: tagRes.data,
          sensors: [],
          selectedColumns: [],
          reportData: [],
          offset: {},
          reportsModel: [],
          showReport: false,
          columnDef: [],
          currentComp: compData,
          sampleUnitValue: "",
          fromDate: "",
		  faultCodeDefined : true
        });
      /*  this.fromDateRef.current.value = "";
        if(this.toDateRef && this.toDateRef.current != null){
          this.toDateRef.current.value = "";
        } */
        this.clearFormField();
      }
    } catch (e) {
      console.log(e);
    }
  }

  clearFormField = () => {
    this.setState({
      reportsModel: [],
	  faultCodeDefined : true
    });
  }

  dateFormatter = (cell, row) => {
    return (
      (this.state.sampleUnitValue != "runHours" && this.state.sampleUnitValue != "energy") ?
      <span>{moment.unix(cell).format("MM/DD/YYYY HH:mm:ss")}</span> : <span>{moment.unix(cell).format("MM/DD/YYYY")}</span>
    );
  }

  dateFaultFormatter = (cell, row) => {
    return (
      <span>{moment.unix(cell).format("MM/DD/YYYY HH:mm:ss")}</span>
    );
  }

  faultFormatter = (cell, row) => {	
    return (
      <span>{row.severity != "Binary" ? this.state.currentComp.faultcodes[row.severity][0].codes[cell] : ""}</span>
    );
  }

  columnForamtter = (cell, row, idx, formatterData) => {
    return (
      <span>{(cell) ? cell.toFixed(2) : "0.00"}</span>
    );
  }
  
  renameKey = (obj, oldKey, newKey) => {
	obj[newKey] = obj[oldKey];
	delete obj[oldKey];
  }
  
  generateReport = () => {
	try {
			let faultPath = "", obj = {};
			if (this.validate()) {
				let sensorList = [], sensorNames = [], offsetValue = '';	

				this.state.reportsModel.map((data, id) => {
				  if (data.checked == true) {
					sensorList.push(data.measureProperty);
					sensorNames.push(`${data.name} (${data.measureUnits})`);
					offsetValue = data.offset;
					//console.log(data);
					this.tagHandler(data);
				  }
				});
				
				this.setState({
				  errMsg: "",
				  startDate: this.state.startDate,
				  endDate: this.state.endDate,
				  sensorNames: sensorNames,
				  offsetValue: offsetValue
				});
				
				let selectedStartHour = this.state.startTime == "0:00" ? this.state.startTime : ((this.state.startTime / 60) / 60) + ":00";
				let selectedEndHour = this.state.endTime == "23:00" ? this.state.endTime : ((this.state.endTime / 60) / 60) + ":00";
				//let startDateWithTime = moment.utc(this.state.startDate + " " + selectedStartHour, "MM/DD/YYYY HH:mm").unix();		
				//let endDateWithTime = moment.utc(this.state.endDate + " " + selectedEndHour, "MM/DD/YYYY HH:mm").unix();
				
				let startDateWithTime = moment(this.state.startDate + " " + selectedStartHour, "MM/DD/YYYY HH:mm").unix();		
				let endDateWithTime = moment.utc(this.state.endDate + " " + selectedEndHour, "MM/DD/YYYY HH:mm").unix();
				  
				if (this.state.sampleUnitValue != 'fault') {
				  obj = {
					id: this.compRef.current.value.split(",")[1],
					start: startDateWithTime, //moment(this.state.startDate, "MM/DD/YYYY").startOf('day').unix(),
					end: endDateWithTime, //moment(this.state.endDate, "MM/DD/YYYY").endOf('day').unix(),
					sensors: sensorList,
					sampling_unit: "hours"
				  }
				} else {
				  //let startTime = moment(this.state.startDate, "MM/DD/YYYY").startOf('day').unix();
				  //let endTime = moment(this.state.endDate, "MM/DD/YYYY").endOf('day').unix();				  			  
		
				  //faultPath = `https://dev.exactspace.co/utvapi/faults?filter[where][compressorName]=${this.compRef.current.value.split(",")[1]}&filter[where][startTime][gte]=${startTime}&filter[where][endTime][lte]=${endTime}`;
				  //faultPath = config.META_API_URL +  `faults?filter[where][compressorName]=${this.compRef.current.value.split(",")[1]}&filter[where][startTime][gte]=${startTime}&filter[where][endTime][lte]=${endTime}`;
				  
				  faultPath = config.META_API_URL +  `faults?filter[where][compressorName]=${this.compRef.current.value.split(",")[1]}&filter[where][startDateWithTime][gte]=${startDateWithTime}&filter[where][endDateWithTime][lte]=${endDateWithTime}`;
				}

				if (sensorList.length == 0 && this.state.sampleUnitValue != 'fault') {
				  this.setState({
					reportData: [],
					sensorNames: [],
					showReport: false
				  });
				  return false;
				}
			}
			
			var self = this;
        if (this.state.sampleUnitValue != 'fault') {
          axios
            .post(self.state.reportsUrl, obj)
            .then(function (response) {
              let columnDef = [];
              columnDef.push({
                dataField: "time",
                text: "Date (MM/DD/YYYY)",
                formatter: self.dateFormatter
              })
			  debugger;
              if (response.data) {
                Object.keys(response.data[0]).map((data, idx) => {
                  if (data != 'time') {
                    columnDef.push({
                      dataField: self.state.sensorNames[idx - 1],
                      text: self.state.sensorNames[idx - 1],
                      formatter: self.columnForamtter,
                      formatExtraData: {
                        offset: self.state.offsetValue
                      }
                    })
                  }
                })
				let exportExcel = [];
				exportExcel = response.data
				if(exportExcel.length > 0)
				{												
					for (var i = 0; i < exportExcel.length; i++) {	
						var contentKeys = Object.keys(exportExcel[i]);
						for (var j = 0; j < contentKeys.length; j++) {	
							if (contentKeys[j] != 'time') {
								exportExcel[i][contentKeys[j]] = exportExcel[i][contentKeys[j]] == null ? 0 : exportExcel[i][contentKeys[j]];
							}
						}						
					}
					const arr = exportExcel;
					for (var i = 0; i < obj.sensors.length; i++) {
						arr.forEach( objResult => self.renameKey( objResult, obj.id + '__' + obj.sensors[i], self.state.sensorNames[i]));
					}
					exportExcel = arr;
				}				
                self.setState({
                  reportData: exportExcel,
				  exportToExcel: exportExcel				  
                })
              }
              self.setState({
                columnDef: columnDef,
                showReport: true
              })
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
			//debugger;
			if(Object.entries(this.state.currentComp.faultcodes).length === 0 && this.state.currentComp.faultcodes.constructor === Object)
			{
				this.setState({
					faultCodeDefined : false
				});
				return false;
			}
			
          axios
            .get(faultPath)
            .then(function (response) {
              let columnDef = [{
                dataField: "startTime",
                text: "Start Date",
                formatter: self.dateFaultFormatter
              },
              {
                dataField: "endTime",
                text: "End Date",
                formatter: self.dateFaultFormatter
              },
              {
                dataField: "severity",
                text: "Severity"
              },
              {
                dataField: "faultmessage",
                text: "Status Message"               
              }];
              if (response.data) {
                self.setState({
                  reportData: response.data,
                  exportToExcel: response.data
                })
              }
              self.setState({
                columnDef: columnDef,
                showReport: true
              })
            })
            .catch(function (error) {
              console.log(error);
            });
        }
	}
	catch (e)
	{
	}
  }

 /* getReport = async (e) => {
    try {
      if (this.validate()) {
        let faultPath = "";

        this.setState({
          errMsg: "",
          startDate: this.fromDateRef.current.value,
          endDate: this.toDateRef.current.value
        }); 

        let sensorList = [], sensorNames = [], offsetValue = '';
		debugger;
        this.state.reportsModel.map((data, id) => {
          if (data.checked == true) {
            sensorList.push(data.measureProperty);
            sensorNames.push(`${data.name} (${data.measureUnits})`);
            offsetValue = data.offset;
            console.log(data);
            this.tagHandler(data);
          }
        });
        this.setState({
          sensorNames: sensorNames,
          offsetValue: offsetValue
        });

        let obj = {};
        if (this.state.sampleUnitValue != 'fault') {
          obj = {
            id: this.compRef.current.value.split(",")[1],
            start: moment(this.fromDateRef.current.value, "YYYY-MM-DD").add('days', 1).utc().startOf('day').unix(),
            end: moment(this.toDateRef.current.value, "YYYY-MM-DD").add('days', 1).utc().endOf('day').unix(),
            sensors: sensorList
          }
        } else {
          let startTime = moment(this.fromDateRef.current.value, "YYYY-MM-DD").add('days', 1).utc().startOf('day').unix();
          let endTime = moment(this.toDateRef.current.value, "YYYY-MM-DD").add('days', 1).utc().endOf('day').unix();
          faultPath = `https://dev.exactspace.co/utvapi/faults?filter[where][compressorName]=${this.compRef.current.value.split(",")[1]}&filter[where][startTime][gte]=${startTime}&filter[where][endTime][lte]=${endTime}`;
        }

        if (sensorList.length == 0 && this.state.sampleUnitValue != 'fault') {
          this.setState({
            reportData: [],
            sensorNames: [],
            showReport: false
          });
          return false;
        }

        var self = this;
        if (this.state.sampleUnitValue != 'fault') {
          axios
            .post(self.state.reportsUrl, obj)
            .then(function (response) {
              let columnDef = [];
              columnDef.push({
                dataField: "time",
                text: "Date",
                formatter: self.dateFormatter
              })
              if (response.data) {
                Object.keys(response.data[0]).map((data, idx) => {
                  if (data != 'time') {
                    columnDef.push({
                      dataField: data,
                      text: self.state.sensorNames[idx - 1],
                      formatter: self.columnForamtter,
                      formatExtraData: {
                        offset: self.state.offsetValue
                      }
                    })
                  }
                })
                self.setState({
                  reportData: response.data,
                  exportToExcel: response.data
                })
              }
              self.setState({
                columnDef: columnDef,
                showReport: true
              })
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          axios
            .get(faultPath)
            .then(function (response) {
              let columnDef = [{
                dataField: "startTime",
                text: "Start Date",
                formatter: self.dateFaultFormatter
              },
              {
                dataField: "endTime",
                text: "End Date",
                formatter: self.dateFaultFormatter
              },
              {
                dataField: "severity",
                text: "Severity"
              },
              {
                dataField: "faultcode",
                text: "Status Message",
                formatter: self.faultFormatter
              }];
              if (response.data) {
                self.setState({
                  reportData: response.data,
                  exportToExcel: response.data
                })
              }
              self.setState({
                columnDef: columnDef,
                showReport: true
              })
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    } catch (e) {
      console.log(e);
    }
  } */

  validate = () => {
    let errMsg = "";
    if (this.compRef.current.value == "") {
      errMsg = "Please select a compressor";
    } else if (this.state.startDate == ""){
      errMsg = "Please select a From Date";
    } else if (this.state.toDate == ""){
      errMsg = "Please select a To Date";
    } 
    if (errMsg != "") {
      this.setState({ errMsg: errMsg });
      return false;
    }
    return true;
  }

  tagHandler = (row) => {
    let selectedTag = [...this.state.sensors];
    let selectedColumns = [...this.state.selectedColumns];
    let offset = { ...this.state.offset };
    if (selectedTag.indexOf(row.measureProperty) < 0) {
      if (row.offset && row.offset.operator != "") {
        offset[`${this.compRef.current.value.split(",")[1]}__${row.measureProperty}`] = row.offset;
        this.setState({
          offset: offset
        })
      }
      this.setState({
        sensors: [...this.state.sensors, row.measureProperty],
        selectedColumns: [...this.state.selectedColumns, row.name]
      })
    } else {
      let idx = selectedTag.indexOf(row.measureProperty);
      selectedTag.splice(idx, 1);
      selectedColumns.splice(idx, 1);
      if (Object.keys(this.state.offset).indexOf(`${this.compRef.current.value.split(",")[1]}__${row.measureProperty}`) >= 0) {
        delete offset[`${this.compRef.current.value.split(",")[1]}__${row.measureProperty}`];
      }
      this.setState({ sensors: selectedTag, selectedColumns: selectedColumns, offset: offset });
    }
  }

  render() {
    const tableColDef = [{
      dataField: 'name',
      text: 'Tags'
    }];

    const selectRowTag = {
      mode: 'checkbox',
      classes: 'form-check-input',
      selectColumnStyle: { width: '40px' },
      selected: this.state.selectedColumns,
      onSelect: this.tagHandler.bind(this),
      clickToSelect: true,
      hideSelectAll: true
    };

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      sizePerPage: 25,
      hideSizePerPage: true,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      sizePerPageList: [{
        text: '25', value: 25
      }]
    };

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <div style={{ "width": "10%", "float": "left" }}> <h4>Reports</h4></div>
                {this.state.showReport && <div style={{ "width": "10%", "float": "right" }}><ExportCSV csvData={this.state.exportToExcel} fileName={this.state.sampleUnitValue + '_Trends_' + this.state.startDate + '_' + this.state.endDate} /></div>}
              </CardHeader>
              <CardBody>
                {((this.state.errMsg && this.state.errMsg !== "")) ? (
                  <SnackbarContent
                    message={
                      this.state.errMsg !== ""
                        ? this.state.errMsg
                        : ""
                    }
                    color={
                      this.state.errMsg !== ""
                        ? "warning"
                        : ""
                    }
                  />
                ) : (
                    ""
                  )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {(this.state.compData.length > 0) ?
                      (<div style={{ "width": "100%" }}>
                        <div style={{ "width": "200px", "float": "left", "marginRight": "10px" }}>
                          <div>
                            <select className="custom-select" onChange={this.loadTag} ref={this.compRef}>
                              <option value="">Select Compressors</option>
                              {
                                this.state.compData.map((data) => {
                                  return <option value={data.id + "," + data.name}>{data.name}</option>
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                      ) : ""}
                    <div style={{ "width": "180px", "float": "left", "marginRight": "10px" }}>
                      <select className="custom-select" onChange={this.change} value={this.state.sampleUnitValue}>
                        <option value="">Select Report</option>
                        <option value="runHours">Run Hour</option>
                        <option value="energy">Energy</option>
                        <option value="critical">Critical Parameters</option>
                        <option value="fault">Fault</option>
                      </select>
                    </div>
                    <div style={{ "width": "435px", "float": "left", "paddingTop": "0px","cursor":"pointer" }}>
						
					<DateRangePicker dateFormat="MM/DD/YYYY" startDate={this.state.startDate ? this.state.startDate : new Date()} endDate={this.state.endDate ? this.state.endDate : new Date()} onApply = {this.handleEvent} >
						<button type="button" class="btn btn-outline-info">Select Dates &nbsp;&nbsp;<i class="fa fa-calendar" aria-hidden="true"></i> {this.state.startDate && <span style={{"font-weight":"bold","paddingLeft":"10px"}}>From : {this.state.startDate} - To : {this.state.endDate}</span> }</button>
					</DateRangePicker>
                    </div>
					<div style={{ "width": "80px", "float": "left", "marginRight": "5px" }}>                                                        						
						<TimePicker start="00:00" end="23:00" format={24} step={60} onChange={this.handleStartTimeChange} value={this.state.startTime} />							
					</div>	
					<div style={{ "width": "80px", "float": "left", "marginRight": "0px" }}>						                           						
						<TimePicker start="00:00" end="23:00" format={24} step={60} onChange={this.handleEndTimeChange} value={this.state.endTime} />							
					</div>
                    <div style={{ "float": "left", "width": "100%" }}>
                      {
                        this.state.reportsModel.map((option, i) => {
                          return <label style={{ "float": "left", "padding": "10px" }}>
                            <input
                              type="checkbox" value={i} onClick={this.handleClick} />
                            {option.name}
                          </label>
                        })
                      }
                    </div>

                  </GridItem>

                  <GridItem sm={12} md={12}>
				  
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={this.generateReport}
                      className="text-right"
                    >
					
					
                      Show Reports
                    </Button>
					<div style={{"display" : this.state.faultCodeDefined == false ? "block" : "none","padding":"10px","fontWeight":"bold"}}>Fault Code not Defined for this compressor</div>
                  </GridItem>
                  {(this.state.showReport) ? (
                    <GridItem sm={12} md={12}>
                      <hr></hr>
                      <h3>Report Status</h3>
                      <Table keyField="date"
                        data={this.state.reportData}
                        columns={this.state.columnDef}
                        noDataIndication="No Data Available"
                        pagination={ paginationFactory(options) } />
						
                    </GridItem>
                  ) : ""}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default Reports;
