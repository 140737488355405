import { client } from "../index";
import { URL_OEM } from "../apiconstant";

export const getOem = async () => {
  return await client.get(URL_OEM);
};

export const createOem = async (data) => {
  return await client.post(URL_OEM, data);
};

export const updateOem = async (oem_id, data) => {
  return await client.put(`${URL_OEM}/${oem_id}`, data);
};

export const getOemDetails = async (oem_id) => {
  return await client.get(`${URL_OEM}/${oem_id}`);
};

export const deleteOem = async (oem_id) => {
  return await client.delete(`${URL_OEM}/${oem_id}`);
};
