/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import axios from "axios";
//Get Meta and Data URL from config file based on environment
import { config } from "../../config/config";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    
  }

  componentDidMount(){
    this.getNotifications();
  }

  getNotifications(){
    var self = this;
    axios
      //.post("https://exactspace.co/compressor/data", {
	  .post(config.DATA_API_URL + "compressor/data", {
        id: "notification",
        sensors: ["msg"]
      })
      .then(function (response) {
        console.log(response);
        if(response.data !== ""){
          var data = response.data.pop();
          self.setState({
            active: data.compressor_status__active || 0,
            servicedue: data.compressor_status__service_due || 0,
            fault: data.compressor_status__fault || 0
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const { classes } = this.props;
	if(window.localStorage.getItem("userId") == 17 || window.localStorage.getItem("userId") == 18 ){
		return false;
	}
    return (
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>Notifications</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <SnackbarContent
                message={
                  "High Temperature 104 deg C reported at xylo valves on 29-Sep-19 at 14:33:20"
                }
                icon={AddAlert}
              />
              <SnackbarContent
                message={
                  "Energy Consumption is higher than recommended levels at Lethar India (P) Ltd., on 29-Sep-19 at 10:24:19"
                }
                icon={AddAlert}
              />
              <SnackbarContent
                message={
                  "Low voltage reported at ST Control Valves on 29-Sep-19 at 06:18:02"
                }
                icon={AddAlert}
              />
              <SnackbarContent
                message={
                  "Maintenance is due for Compressor ID SCM1256 at PSG Hospitals on 28-Sep-19"
                }
                icon={AddAlert}
              />
              <SnackbarContent
                message={
                  "Compressor at Atlas Pneumatics has reported breakdown on 28-sep-19 at 20:15:48"
                }
                icon={AddAlert}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Notifications);
