/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "assets/css/material-dashboard-react.css?v=1.7.0";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CustomInput from "components/CustomInput/CustomInput";

import axios from "axios";
//Get Meta and Data URL from config file based on environment
import { config } from "../../config/config";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Ingest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preferenceType: "form",
      scsMsg:"",
      errMsg: ""
    };
  }

  componentDidMount(){
    this.setTime();
  }

  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
  }

  setTime(){
    var now = new Date();
    var utcString = now.toISOString().substring(0,19);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    var localDatetime = year + "-" +
                      (month < 10 ? "0" + month.toString() : month) + "-" +
                      (day < 10 ? "0" + day.toString() : day) + "T" +
                      (hour < 10 ? "0" + hour.toString() : hour) + ":" +
                      (minute < 10 ? "0" + minute.toString() : minute) +
                      utcString.substring(16,19);
    setTimeout(()=>{
      var datetimeField = document.getElementById("time");
      if(datetimeField){
        datetimeField.value = localDatetime;
      }
    },100)
  }

  onPreferenceChanged(e) {
    this.setState({
      preferenceType: e.currentTarget.value,
      errMsg: "",
      scsMsg: ""
    });
    this.setTime();
    console.log(this.state.preferenceType);
  }

  getTimeStamp(datestring){
    console.log(datestring)
    return new Date(datestring) / 1000;
  }

  reset(){
    document.getElementById('device-id').value = "";
    document.getElementById('d0').value = "";
    document.getElementById('d1').value = "";
    document.getElementById('d2').value = "";
    document.getElementById('d3').value = "";
    document.getElementById('d4').value = "";
    document.getElementById('d5').value = "";
    document.getElementById('d6').value = "";
    document.getElementById('d7').value = "";
    document.getElementById('d8').value = "";
    document.getElementById('d9').value = "";
    document.getElementById('d10').value = "";
    document.getElementById('d11').value = "";
    document.getElementById('d12').value = "";
    //document.getElementById('txtCompData').value = "";

    document.getElementById('active').value = "";
    document.getElementById('inactive').value = "";
    document.getElementById('service_due').value = "";

    document.getElementById('nt_msg').value = "";
  }

  async ingestData(){
    try{
      let postBody = "";
      this.setState({scsMsg: "", errMsg: ""})
      if(this.state.preferenceType == 'textArea'){
        postBody = JSON.parse(document.getElementById('txtCompData').value); 
      }else{
        
        postBody = {
          id: document.getElementById('device-id').value,
          timestamp: this.getTimeStamp(document.getElementById('time').value),
          D0: document.getElementById('d0').value,
          D1: document.getElementById('d1').value,
          D2: document.getElementById('d2').value,
          D3: document.getElementById('d3').value,
          D4: document.getElementById('d4').value,
          D5: document.getElementById('d5').value,
          D6: document.getElementById('d6').value,
          D7: document.getElementById('d7').value,
          D8: document.getElementById('d8').value,
          D9: document.getElementById('d9').value,
          D10: document.getElementById('d10').value,
          D11: document.getElementById('d11').value,
          D12: document.getElementById('d12').value,
        }
      }
      
      //let data = await axios.post("https://exactspace.co/compressor/ingest", postBody)
	  let data = await axios.post(config.DATA_API_URL + "compressor/ingest", postBody)
      this.setState({
        scsMsg: "Compressor Data ingested Successfully"
      });

      this.reset();

    }catch(e){
      console.log(e);
      this.setState({
        errMsg: "Unable to ingest Compressor Data"
      });
    }
  }

  async dashboardInfo(){
    try{
      let postBody = "";
      this.setState({scsMsg: "", errMsg: ""})
      
      postBody = {
        id:"compressor_status",
        timestamp: new Date().getTime() /1000, 
        active: document.getElementById('active').value || 0,
        service_due: document.getElementById('service_due').value || 0,
        inactive: document.getElementById('inactive').value || 0,
      }
      
      //let data = await axios.post("https://exactspace.co/compressor/ingest", postBody)
	  let data = await axios.post(config.DATA_API_URL + "compressor/ingest", postBody)
      this.setState({
        scsMsg: "Data ingested Successfully"
      });

      this.reset();

    }catch(e){
      console.log(e);
      this.setState({
        errMsg: "Unable to ingest Data"
      });
    }
  }

  async NotificationInfo(){
    try{
      let postBody = "";
      this.setState({scsMsg: "", errMsg: ""})
    
      postBody = {
        id:"notification",
        timestamp: new Date().getTime() /1000, 
        msg: document.getElementById('nt_msg').value,
      }

      if(postBody.msg !== ""){
        //let data = await axios.post("https://exactspace.co/compressor/ingest", postBody)
		let data = await axios.post(config.DATA_API_URL + "compressor/ingest", postBody)
        this.setState({
          scsMsg: "Data ingested Successfully"
        });

        this.reset();
      }

    }catch(e){
      console.log(e);
      this.setState({
        errMsg: "Unable to ingest Data"
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>Ingest Compressor Data</h4>
        </CardHeader>
        <CardBody>
          {this.state.errMsg != "" || this.state.scsMsg != "" ? (
              <SnackbarContent
                message={
                  this.state.errMsg != ""
                    ? this.state.errMsg
                    : this.state.scsMsg != ""
                    ? this.state.scsMsg
                    : ""
                }
                color={
                  this.state.errMsg != ""
                    ? "danger"
                    : this.state.scsMsg != ""
                    ? "success"
                    : ""
                }
              />
            ) : (
              ""
          )}
          <GridContainer >
          {this.state.preferenceType === 'textArea' ? 
            <GridItem  xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Comporessor Data to be in Json Format"
                id="txtCompData"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 15,
                  disabled: this.state.preferenceType != 'textArea'
                }}
              />
            </GridItem>:
            <GridItem  xs={12} sm={6} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Device Id"
                      id="device-id"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText=""
                      id="time"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form',
                        type: "datetime-local",
                        max: new Date()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Run Time"
                      id="d0"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Load Time"
                      id="d1"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Air Pressure"
                      id="d2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Air Temperature"
                      id="d3"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Oil Filter used time"
                      id="d4"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Air Filter used Time"
                      id="d5"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Control State"
                      id="d6"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Motor Current R"
                      id="d7"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Motor Current Y"
                      id="d8"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Motor Current B"
                      id="d9"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Run State 1 address- 7"
                      id="d10"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Run State 1 address- 8"
                      id="d11"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Oil Separator Used Time"
                      id="d12"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: this.state.preferenceType != 'form'
                      }}
                    />
                  </GridItem>
                </GridContainer>
            </GridItem>
          }
          </GridContainer>       
        </CardBody>
        <CardFooter>
          <Button color="info" onClick={this.ingestData.bind(this)}>Ingest Data</Button>
        </CardFooter>
      </Card>
      <br/>
      <br/>
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>Compressor Active info</h4>
        </CardHeader>
        <CardBody>
          <GridContainer >
            <GridItem  xs={12} sm={6} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Active"
                      id="active"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Inactive"
                      id="inactive"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Service Due"
                      id="service_due"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
            </GridItem>
          </GridContainer>       
        </CardBody>
        <CardFooter>
          <Button color="info" onClick={this.dashboardInfo.bind(this)}>push Data</Button>
        </CardFooter>
      </Card>
      <br/><br/>
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>Compressor Notification info</h4>
        </CardHeader>
        <CardBody>
          <GridContainer >
            <GridItem  xs={12} sm={6} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Notification message"
                      id="nt_msg"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
            </GridItem>
          </GridContainer>       
        </CardBody>
        <CardFooter>
          <Button color="info" onClick={this.NotificationInfo.bind(this)}>push Data</Button>
        </CardFooter>
      </Card>
      </div>
    );
  }
}

Ingest.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Ingest);
