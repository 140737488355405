import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { initUser } from "../../../rest/users";

class CreateUser extends React.Component { 
  state = {
    errMsg: ""
  };

  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
    this.passRef = React.createRef();
    this.cnfmPassRef = React.createRef();  
  }

  componentDidMount() {}
  
  createUser = async () => {
    try {
      this.setState({ scsMsg: "", errMsg: "" });
      if (this.validate(this.emailRef.current.value, this.passRef.current.value, this.cnfmPassRef.current.value)) {
        await initUser({ email: this.emailRef.current.value, password: this.passRef.current.value });
        this.props.postedData(this.emailRef.current.value);
      }
    } catch (e) {
      console.log(e);
      if (e.response) {
        this.setState({ errMsg: e.response.data.error.message });
      }
    }  
  }

  reset(){
    this.emailRef.current.value = "";
    this.passRef.current.value = "";
    this.cnfmPassRef.current.value = "";
  }

  validate = (email, password, cnfPass) => {
    let eml = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errMsg;
    if (!email || email === "") {
      this.emailRef.current.focus();
      errMsg= "Please enter a valid email";
    } else if (!eml.test(email)) {
      this.emailRef.current.focus();
      errMsg= "Please enter a valid email";
    } else if (!password || password === "") {
      this.passRef.current.focus();
      errMsg= "Please enter a valid password";
    } else if (!cnfPass || cnfPass === "") {
      this.cnfmPassRef.current.focus();
      errMsg= "Please enter a valid Confirm Password"
    } else if (password !== cnfPass) {
      this.cnfmPassRef.current.focus();
      errMsg= "Password and Confirm password didn't match"
    }
    if(errMsg){
      this.setState({errMsg: errMsg});
      return false;
    }
    return true;
  }

  render() {
    return (
      <div>
        
            <div className="pad_hor_15">
              {this.state.errMsg !== "" ? (
                <SnackbarContent
                  message={
                    this.state.errMsg !== ""
                      ? this.state.errMsg
                      : ""
                  }
                  color={
                    this.state.errMsg !== ""
                      ? "warning"
                      : ""
                  }
                />
                ) : (
                  ""
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group row">
                          <label className="col-sm-3 col-form-label txt-col-blk">Email address</label>
                          <div className="col-sm-8">
                              <input type="text" className="form-control" ref={this.emailRef}/>
                          </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group row">
                          <label className="col-sm-3 col-form-label txt-col-blk">Password</label>
                          <div className="col-sm-8">
                              <input type="password" className="form-control" ref={this.passRef}/>
                          </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="form-group row">
                          <label className="col-sm-3 col-form-label txt-col-blk">Confirm Password</label>
                          <div className="col-sm-8">
                              <input type="password" className="form-control" ref={this.cnfmPassRef}/>
                          </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        type="button"
                        variant="contained"
                        className="float_rght"
                        color="primary"
                        onClick={this.createUser}
                    >
                        Create
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className="float_rght"
                        onClick={this.props.hidePopup}
                    >
                        Cancel
                    </Button>
                </GridItem>
              </GridContainer>
            </div>
      </div>
    );
  }  
}

export default CreateUser;
