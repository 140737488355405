import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "react-bootstrap-table-next";
import axios from "axios";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import moment from 'moment';
import { getCompressor } from "../../rest/compressor";
import { getTags } from "../../rest/tag";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { ExportCSV } from "./ExportToExcel";

import { Line } from "react-chartjs-2";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
//Get Meta and Data URL from config file based on environment
import { config } from "../../config/config";

class Trends extends React.Component {

  state = {
    compData: {},
    tagData: [],
    sensors: [],
    selectedColumns: [],
    showReport: false,
    reportData: [],
    fromDate: "",
    offset: {},
    errMsg: "",
    exportToExcel: [],
    selectedOption: "",
    exportFileName: '',
    startDate: '',
    endDate: '',
    showChart: false,
    chartProperties: {
      labels: [],
      datasets: [
        {
          label: '',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(153,54,40,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        }
      ]
    }
  }



  constructor(props) {
    super(props);
    this.compRef = React.createRef();
    this.fromDateRef = React.createRef();
    this.toDateRef = React.createRef();
    this.previousDate = new Date().setMonth(new Date().getMonth() - 1);
  }

  async componentDidMount() {

    this.loadCompressor();
  }

  componentDidUpdate() { }
  
  handleEvent = (event, picker) => {
		let fromDate = moment(picker.startDate,'DD/MM/YYYY').format('MM-DD-YYYY');
		let toDate = moment(picker.endDate,'DD/MM/YYYY').format('MM-DD-YYYY');
		let maxDate = moment(picker.startDate,'DD/MM/YYYY').add('days', 30).format('MM-DD-YYYY');
		
		this.setState({
			startDate : fromDate,
			endDate : toDate,
			maxDate : maxDate,
			selectLabel : ""
		});
    }

  loadCompressor = async () => {
    try {
      let compRes = await getCompressor();
      let comData = compRes.data.filter(comp => {
        return JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].c != undefined ? JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].c.includes(comp.name) : JSON.parse(window.localStorage.getItem("profileInfo")).accessItems[0].compressors.includes(comp.name);
      })
      this.setState({
        compData: comData
      })
    } catch (e) {
      console.log(e);
    }
  }

  loadTag = async (data) => {
    try {
      if (this.compRef.current.value != "") {
        let query = { filter: { "where": { "compressorId": this.compRef.current.value.split(",")[0], "showTrends": "true" } } };
        let tagRes = await getTags(query);
        this.setState({
          tagData: tagRes.data,
          sensors: [],
          selectedColumns: [],
          reportData: [],
          offset: {},
          showReport: false,
          columnDef: [],
          showChart: false,
          exportToExcel: ''
        });
        this.fromDateRef.current.value = "";
        if (this.fromDateRef.current.value != "") {
          this.toDateRef.current.value = "";
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  dateFormatter = (cell, row) => {
    return (
      <span>{moment.unix(cell).format("MM/DD/YYYY")}</span>
    );
  }

  change = (event) => {
    this.setState({
      sampleUnitValue: event.target.value
    })
  }

  columnForamtter = (cell, row, idx, formatterData) => {

    return (
      <span>{(cell).toFixed(0)}</span>
    );

  }

  getReport = async (sensor, offset) => {
    try {
      this.setState({
        errMsg: "",
		/*startDate: this.fromDateRef.current.value,
        endDate: this.toDateRef.current.value*/
        startDate: this.state.startDate, 
        endDate: this.state.endDate
      });

      if (this.validate()) {
        let obj = {
          id: this.compRef.current.value.split(",")[1],
		  /*start: moment(this.fromDateRef.current.value, "YYYY-MM-DD").add('days', 1).utc().startOf('day').unix(),
          end: moment(this.toDateRef.current.value, "YYYY-MM-DD").add('days', 1).utc().endOf('day').unix(),*/
          start: moment(this.state.startDate, "MM/DD/YYYY").startOf('day').unix(), 
          end: moment(this.state.endDate, "MM/DD/YYYY").endOf('day').unix(),
          sensors: [sensor]
        }

        if (this.state.sampleUnitValue && this.state.sampleUnitValue != "") {
          obj.sample_unit = this.state.sampleUnitValue;
        }


        var self = this;
        axios
          //.post("https://exactspace.co/compressor/data", obj)
		  .post(config.DATA_API_URL + "compressor/data", obj)
          .then(function (response) {
            let columnDef = [];
            columnDef.push({
              dataField: "time",
              text: "Date",
              formatter: self.dateFormatter
            })
            if (response.data) {
              Object.keys(response.data[0]).map((data, idx) => {
                if (data != 'time') {
                  columnDef.push({
                    dataField: data,
                    text: self.state.selectedColumns[(idx - 1)],
                    formatter: self.columnForamtter,
                    formatExtraData: {
                      offset: self.state.offset[data]
                    }
                  })
                }
              })


              let labelsArray = [], dataArray = [];
              response.data.map((item, key) => {
                Object.keys(item).map(function (key) {
                  if (key == 'time') {
                    let dateVal
                    if (self.state.sampleUnitValue == "hours")
                      dateVal = moment.unix(item.time).format("HH:mm:ss");
                    else if (self.state.sampleUnitValue == "days")
                      dateVal = moment.unix(item.time).format("DD/MM/YYYY");
                    else if (self.state.sampleUnitValue == "")
                      dateVal = moment.unix(item.time).format("DD/MM/YYYY HH:mm:ss");
                    else
                      dateVal = moment.unix(item.time).format("HH:mm:ss");
                    labelsArray.push(dateVal);
                  }
                  else {
                    let itemValue = item[key];
                    dataArray.push(itemValue.toFixed(1));
                  }
                });

              }
              );


              self.setState({
                chartProperties: {
                  labels: labelsArray,
                  datasets: [
                    {
                      label: self.state.selectedColumns,
                      data: dataArray,
                      backgroundColor: 'rgba(255,99,132,0.4)'
                    }]
                }
              })
            }


            self.setState({
              columnDef: columnDef,
              showReport: true,
              exportToExcel: response.data
            })


          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  validate = () => {
    let errMsg = "";
    if (this.compRef.current.value == "") {
      errMsg = "Please select a compressor";
	/*} else if (this.fromDateRef.current.value == "") {
      errMsg = "Please select a From Date";
    } else if (this.toDateRef.current.value == "") {*/
    } else if (this.state.startDate.value == "") { 
      errMsg = "Please select a From Date";
    } else if (this.state.endDate.value == "") { 
      errMsg = "Please select a To Date";
    }
    if (errMsg != "") {
      this.setState({ errMsg: errMsg });
      return false;
    }
    return true;
  }

  tagHandler = (row) => {
    let chartlabels = `${row.name} (${row.measureUnits})`;
    this.setState({
      selectedColumns: [chartlabels],
      chartProperties: {
        datasets: [
          {
            label: row.name
          }]
      }
    })
    debugger;
    this.getReport(row.measureProperty, row.offset);
  }

  render() {
    const tableColDef = [{
      dataField: 'name',
      text: 'Tags'
    }];

    const selectRowTag = {
      mode: 'radio',
      classes: 'form-check-input',
      selectColumnStyle: { width: '40px' },
      selected: this.state.selectedColumns,
      onSelect: this.tagHandler.bind(this),
      clickToSelect: true,
      hideSelectAll: true
    };

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <div style={{ "width": "10%", "float": "left" }}> <h4>Trends</h4></div>
                {this.state.showReport && <div style={{ "width": "10%", "float": "right" }}><ExportCSV csvData={this.state.exportToExcel} fileName={this.state.selectedColumns + '_Trends_' + this.state.startDate + '_' + this.state.endDate} /></div>}
              </CardHeader>
              <CardBody>
                {((this.state.errMsg && this.state.errMsg !== "")) ? (
                  <SnackbarContent
                    message={
                      this.state.errMsg !== ""
                        ? this.state.errMsg
                        : ""
                    }
                    color={
                      this.state.errMsg !== ""
                        ? "warning"
                        : ""
                    }
                  />
                ) : (
                    ""
                  )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {(this.state.compData.length > 0) ?


                      <div style={{ "width": "100%" }}>
                        <div style={{ "width": "150px", "float": "left", "marginRight": "10px" }}>
                          <div>Select Compressors</div>
                          <div>
                            <select className="custom-select" onChange={this.loadTag.bind(this)} ref={this.compRef}>
                              <option value="">Select</option>
                              {
                                this.state.compData.map((data) => {
                                  return <option value={data.id + "," + data.name}>{data.name}</option>
                                })
                              }
                            </select>
                          </div>
                        </div>                        
						<div style={{ "float": "left", "marginRight": "10px" }}>	
							<div>Dates</div>
							<div style={{ "float": "left" }}>
								<DateRangePicker dateFormat="MM/DD/YYYY" startDate={this.state.startDate ? this.state.startDate : new Date()} endDate={this.state.endDate ? this.state.endDate : new Date()} onApply = {this.handleEvent} >
									<button type="button" class="btn" style={{ "border": "1px solid #ced4da" }}>Select &nbsp;&nbsp;<i class="fa fa-calendar" aria-hidden="true"></i> {this.state.startDate && <span style={{"font-weight":"bold","paddingLeft":"10px"}}>From : {this.state.startDate} - To : {this.state.endDate}</span> }</button>
								</DateRangePicker>
							</div>
						</div>
                        <div style={{ "width": "180px", "float": "left", "marginRight": "10px" }}>
                          <div>Sampling Frequency</div>
                          <select className="custom-select" onChange={this.change} value={this.state.sampleUnitValue}>
                            <option value="">Select</option>
                            <option value="">All</option>
                            <option value="minutes">Minute Avg</option>
                            <option value="hours">Hourly Avg</option>
                            <option value="days">Daily Avg</option>

                          </select>
                        </div>

                        <br />
                        <div style={{ "float": "left", "width": "100%" }}>
                          {
                            this.state.tagData.map((option, i) => {
                              return <label style={{ "float": "left", "padding": "10px" }}>
                                <input
                                  type="radio"
                                  name="tagGroup"
                                  checked={this.state.radioButton}
                                  onChange={this.tagHandler.bind(this, this.state.tagData[i])}
                                  value={this.state.tagData[i].measureProperty}
                                />
                                <span>{option.name}</span>
                              </label>
                            })
                          }</div>

                      </div> :
                      <div>
                        no data
					</div>}

                  </GridItem>
                  <GridItem sm={12} md={12}>

                    <Line width={300} height={100} data={this.state.chartProperties} />

                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default Trends;
