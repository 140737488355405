import { client } from "../index";
import { URL_DEALER } from "../apiconstant";

export const getDealer = async () => {
  return await client.get(URL_DEALER);
};

export const createDealer = async (data) => {
  return await client.post(URL_DEALER, data);
};

export const updateDealer = async (dealer_id, data) => {
  return await client.put(`${URL_DEALER}/${dealer_id}`, data);
};

export const getDealerDetails = async (dealer_id) => {
  return await client.get(`${URL_DEALER}/${dealer_id}`);
};

export const deleteDealer = async (dealer_id) => {
  return await client.delete(`${URL_DEALER}/${dealer_id}`);
};
