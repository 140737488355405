import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "assets/img/logo.png";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import { authenticate, signup } from "../../rest/login";
import { getUserProfile, getUser } from "../../rest/users";

import { getCustomers, deleteCustomer } from "../../rest/customer"

import "assets/style/reset.css";
import "assets/style/superfish.css";
import "assets/style/prettyPhoto.css";
import "assets/style/jquery.qtip.css";
import "assets/style/style.css";
import "assets/style/animations.css";
import "assets/style/responsive.css";
import "assets/style/custom.css";
import "assets/style/odometer-theme-default.css";
import "assets/fonts/streamline-small/styles.css";
import "assets/fonts/streamline-large/styles.css";
import "assets/fonts/template/styles.css";
import "assets/fonts/social/styles.css";

class Dashboard extends React.Component {
  state = {
    errMsg: "",
    scsMsg: "",
    isSignup: false
  };

  componentDidMount() {
    // check login details
    if (window.localStorage.getItem("token") != null) {
      window.location = "/admin/dashboard";
    }
  }
  
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById("login").click();
    }
  }

  authenticate = async event => {
    try {
      event.preventDefault();
      let email = document.getElementById("email").value;
      let password = document.getElementById("password").value;
      if (this.validate(email, password)) {
        if (!this.state.isSignup) {
          // Authenticate User
          let response = await authenticate({ email, password });
          // fetch user profile info
          let profileInfo = await getUserProfile({ "where": { "email": email } }); 		  
          // persist in local storage
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem("estoken", response.data.id);
          window.localStorage.setItem("userId", response.data.userId);		  
          window.localStorage.setItem("profileInfo", JSON.stringify(profileInfo.data[0]));
		  /*let customerRes = await getCustomers();		  
		  let dealerRes = customerRes.data.filter(customer => customer.id == profileInfo.data[0].customerId);
		  window.localStorage.setItem("dealerInfo", JSON.stringify(dealerRes[0]));*/
		  window.localStorage.setItem("logoName", JSON.stringify(profileInfo.data[0].lastName));
		  window.localStorage.removeItem("selectedCompressor");
          // await getUser();
          window.location = "/admin/dashboard";
        } else {
          await signup({ email, password });
          this.setState({
            isSignup: false,
            scsMsg: "User account created successfully"
          });
          document.getElementById("email").value = "";
          document.getElementById("password").value = "";
        }
      }
    } catch (e) {
      if (e.response) {
        this.setState({ errMsg: e.response.data.error.message });
      }
    }
  };

  validate = (email, password) => {
    let eml = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({ scsMsg: "" });
    if (!email || email === "") {
      document.getElementById("email").focus();
      this.setState({ errMsg: "Please enter a valid email" });
      return false;
    } else if (!eml.test(email)) {
      document.getElementById("email").focus();
      this.setState({ errMsg: "Please enter a valid email" });
      return false;
    } else if (!password || password === "") {
      document.getElementById("password").focus();
      this.setState({ errMsg: "Please enter a valid password" });
      return false;
    }
    return true;
  };

  render() {
    const classes = makeStyles(theme => ({
      "@global": {
        body: {
          backgroundColor: theme.palette.common.white
        }
      },
      paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
      },
      form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
      },
      submit: {
        margin: theme.spacing(3, 0, 2)
      }
    }));

    return (
	<div>
	  <div className={"header-container"}>		
		<div className={"vertical-align-table column-1-1"}>
			<div className={"header clearfix"}>
				<div className={"logo vertical-align-cell"}>
					<div className={"u-logo cursor"}></div>
				</div>
				<a href="#" className={"mobile-menu-switch vertical-align-cell"}>
					<span className={"line"}></span>
					<span className={"line"}></span>
					<span className={"line"}></span>
				</a>
			</div>
		</div>
	</div>
	<div className={"d-flex align-center login-bg"}>
		<div className={"login-fade d-flex justify-center align-center"}>
			<div className={"login-container mobile-flex d-flex"}>
				<div className={"fx-2 lg-ct-1"}>
					<div className={"d-flex align-center mobile-flex"}><span className={"img-circle"}></span><p className={"login-p1"}>We are aligned to UN SDG Goal of industries, innovation & infrastructure</p></div>
					<div className={"bd-1-wt"}></div>
					<p className={"lg-p2"}>Our Vision is to enable 15% more energy efficiency through the adoption of industrial IoT.</p>
					<div className={"bd-1-wt"}></div>				
				</div>
				<div className={"fx-1 d-flex align-center"}>
					<div className={"lg-fm-bg"}>																							
						{this.state.errMsg !== "" || this.state.scsMsg !== "" ? (
						  <SnackbarContent
							message={
							  this.state.errMsg !== ""
								? this.state.errMsg
								: this.state.scsMsg !== ""
								? this.state.scsMsg
								: ""
							}
							color={
							  this.state.errMsg !== ""
								? "danger"
								: this.state.scsMsg !== ""
								? "success"
								: ""
							}
						  />
						) : (
						  ""
						)}						
						<form >
						  <input id="username"
							className={"lg-ip"}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							placeholder="Email"
							autoFocus
						  />
						  <input id="password"
						    className={"lg-ip"}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							placeholder="Password*"
							onKeyDown={this._handleKeyDown}
						  />
						  <br />
						  <span id="login" className={"lg-btn"} onClick={this.authenticate}>
							{this.state.isSignup ? "Sign Up" : "Sign In"}
						  </span>
						</form>
						<br />
						<Grid container>
						  <Grid item xs>
							<Link href="#" variant="body2">
							  {this.state.isSignup ? "" : ""}
							</Link>
						  </Grid>
						  <Grid item>
							{this.state.isSignup ? (
							  <p
							    className={"lg-p mt-1"}
								href="#"
								variant="body2"
								onClick={() =>
								  this.setState({ isSignup: false, errMsg: "", scsMsg: "" })
								}
							  >
								{"Login"}
							  </p>
							) : (
							  <p
							    className={"lg-p mt-1"}
								href="#"
								variant="body2"
								onClick={() =>
								  this.setState({ isSignup: true, errMsg: "", scsMsg: "" })
								}
							  >
								{"Don't have an account? Sign Up"}
							  </p>
							)}
						  </Grid>
						</Grid>
					</div>
				</div>
			</div>
		</div>
	</div>      
	</div>
    );
  }
}

export default Dashboard;
