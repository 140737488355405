import axios from "axios";
import { config } from "../config/config";

export const client = axios.create({
  baseURL: config.META_API_URL,
  timeout: config.TIMEOUT,
  headers: {
    "Content-Type": "application/json"
  }
});

// request
client.interceptors.request.use(request => onRequestSuccess(request));

// Respose
client.interceptors.response.use(
  response => onResponseSuccess(response),
  error => onResponseError(error)
);

const unauthenticate = () => {
  window.localStorage.clear();
  window.location = "/login/login?msg=session_expired";
};

const onRequestSuccess = request => {
  //pendingReq += 1;
  showLoading();
  const token = window.localStorage.getItem("token");
  const estoken = window.localStorage.getItem("estoken");
  let isMwCall = request.baseURL.endsWith("exactspace/utvapi");
  if (token && isMwCall) {
    request.headers["Authorization"] = `Bearer ${token}`;
  } else if (estoken && !isMwCall) {
    request.headers["Authorization"] = `${estoken}`;
  }
  return request;
};

const onResponseSuccess = response => {
  hideLoading();
  return response;
};
const onResponseError = err => {
  console.log(err);
  const status = err.status || (err.response ? err.response.status : 0);
  hideLoading();
  if (status === 403 || status === 401) {
    if (window.localStorage.getItem('token') !== null) {
      unauthenticate();
    }  
  }
  return Promise.reject(err);
};

const showLoading = () => {
  document.getElementById("loading").classList.remove("display-none");
};

const hideLoading = () => {
  //pendingReq = pendingReq != 0 ? --pendingReq : pendingReq;
  //if (pendingReq == 0) {
  document.getElementById("loading").classList.add("display-none");
  //}
};

export const mwClient = axios.create({
  baseURL: "https://middleware.utvyakta.com/exactspace/utvapi",
  // baseURL: "http://localhost:8000/exactspace/utvapi",
  timeout: config.TIMEOUT,
  headers: {
    "Content-Type": "application/json"
  }
});

// request
mwClient.interceptors.request.use(request => onRequestSuccess(request));

// Respose
mwClient.interceptors.response.use(
  response => onResponseSuccess(response),
  error => onResponseError(error)
);