import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "react-bootstrap-table-next";
import Button from "components/CustomButtons/Button.jsx";
import Modal from "react-bootstrap/Modal";

// rest
import { getTags, postTags, updateTags, deleteTags, invalidateTagsCache } from "../../../rest/tag";

// context
import { TagContext } from "../context";
import { fade } from "@material-ui/core/styles";

class ListTag extends React.Component { 
    
    state = {
        tagData: [],
        showModal: false,
        type: "add",
        tagDetails: {}
    };

    constructor(props) {
        super(props);
        this.nameRef = React.createRef();
        this.measureTypeRef = React.createRef();
        this.nameasurePropertyRef = React.createRef();
        this.measureUnitRef = React.createRef();
        this.displayTagRef = React.createRef();
        this.operatorRef = React.createRef("");
        this.valueRef = React.createRef();
        this.showReportRef = React.createRef();
        this.showTrendsRef = React.createRef();
        this.showRunHoursRef = React.createRef();
        this.showCriticalRef = React.createRef();
        this.alarmRangeLow = React.createRef();
        this.alarmRangeHi = React.createRef();
        this.dataTagId = React.createRef();
        this.servicedueRangeLow = React.createRef();
        this.servicedueRangeHi = React.createRef();
        this.validRangeLow = React.createRef();
        this.validRangeHi = React.createRef();
        this.showEnergyRef = React.createRef();
    }

    editFormatter = (cell, row) => {
        return (
            <div className="text-center">
                <span className="fa fa-pencil-square-o" onClick={() => { this.editTag(row) }}></span>
            </div>    
        );
    }

    deleteFormatter = (cell, row) => {
        return (
            <div className="text-center">
                <span className="fa fa-trash text-center" onClick={() => { this.deleteTag(cell) }}></span>
            </div>    
        );
    }

    editTag = (row) => {
        this.setState({
            showModal: true,
            type: "edit"
        }, () => {
            this.nameRef.current.value = row.name;
            this.measureTypeRef.current.value = row.measureType;
            this.nameasurePropertyRef.current.value = row.measureProperty;
            this.measureUnitRef.current.value = row.measureUnits;
            this.displayTagRef.current.value = row.displayTag;
            this.operatorRef.current.value = (row.offset && row.offset.operator) ? row.offset.operator: "";
            this.valueRef.current.value = (row.offset && row.offset.value) ? row.offset.value : "";
            this.showReportRef.current.value = (row.showReport && row.showReport) ? row.showReport : "";
            this.showRunHoursRef.current.value = (row.showRunHours && row.showRunHours) ? row.showRunHours : "";
            this.showCriticalRef.current.value = (row.showCritical && row.showCritical) ? row.showCritical : "";
            this.showTrendsRef.current.value = (row.showTrends && row.showTrends) ? row.showTrends : "";
            this.alarmRangeHi.current.value = (row.alarmRangeHi) ? row.alarmRangeHi : "";
            this.alarmRangeLow.current.value = (row.alarmRangeLow) ? row.alarmRangeLow : "";
			this.dataTagId.current.value = (row.dataTagId) ? row.dataTagId : "";
			this.servicedueRangeLow.current.value = (row.servicedueRangeLow) ? row.servicedueRangeLow : "";
			this.servicedueRangeHi.current.value = (row.servicedueRangeHi) ? row.servicedueRangeHi : "";
			this.showEnergyRef.current.value = (row.showEnergy && row.showEnergy) ? row.showEnergy : "";
			//this.validRangeLow.current.value = (row.validRangeLow) ? row.validRangeLow : "";
			//this.validRangeHi.current.value = (row.validRangeHi) ? row.validRangeHi : "";
            this.context.state.tagId = row.id;
        })
    };

    deleteTag = (id) => {
        this.setState({
            showModal: true,
            type: "delete",
            tagId: id
        })
    };

    openModal = () => {
        this.setState({
            showModal: true,
            type: "add"
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false
        });
    }

    async componentDidMount() {
        await this.getTags(this.context.state.compressorId);
    }

    async componentWillReceiveProps(nxtProps) {
        if (this.props.compressorId != nxtProps.compressorId) {
            this.context.state.compressorId = nxtProps.compressorId;
            await this.getTags(nxtProps.compressorId);
        }
    }

    async getTags(id) {
        try {
            let query = { filter: { "where": { "compressorId": id } } };
            let data = await getTags(query);
            this.setState({
                tagData: data.data
            })
        } catch (e) {
            console.log(e);
        }   
    }

    postData = async() => {
        try {
            if (this.state.type == "edit") {
                let tagDetails = {
                    name: this.nameRef.current.value,
                    measureType: this.measureTypeRef.current.value,
                    measureProperty: this.nameasurePropertyRef.current.value,
                    measureUnits: this.measureUnitRef.current.value,
                    compressorId: this.context.state.compressorId,
                    displayTag: this.displayTagRef.current.value,
                    showReport: this.showReportRef.current.value,
                    showRunHours: (this.showRunHoursRef.current.value.toLowerCase() == 'true'),
                    showTrends: this.showTrendsRef.current.value,
                    alarmRangeHi: this.alarmRangeHi.current.value,
                    alarmRangeLow: this.alarmRangeLow.current.value,
                    showCritical: (this.showCriticalRef.current.value.toLowerCase() == 'true'), 
                    offset: {
                        operator: this.operatorRef.current.value,
                        value: isNaN(parseInt(this.valueRef.current.value)) ? this.valueRef.current.value : parseFloat(this.valueRef.current.value) 
                    },
					dataTagId: this.dataTagId.current.value,
					servicedueRangeLow: this.servicedueRangeLow.current.value,
					servicedueRangeHi: this.servicedueRangeHi.current.value,
					showEnergy: this.showEnergyRef.current.value,
				//	validRangeLow: this.validRangeLow.current.value,
				//	validRangeHi: this.validRangeHi.current.value,
                    id: this.context.state.tagId
                }
                await updateTags(tagDetails);
                this.setState({
                    showModal: false,
                    type: "add"
                }, async () => {
                    await this.doAfterPostData(this.context.state.compressorId);
                    alert("Tag Updated Successfully");
                    await this.getTags(this.context.state.compressorId); 
                });
            } else if (this.state.type == 'add') {				
                let tagDetails = {					
                    name: this.nameRef.current.value,
                    measureType: this.measureTypeRef.current.value,
                    measureProperty: this.nameasurePropertyRef.current.value,
                    measureUnits: this.measureUnitRef.current.value,
                    compressorId: this.context.state.compressorId,
                    displayTag: this.displayTagRef.current.value,
                    showReport: this.showReportRef.current.value,
                    showRunHours: (this.showRunHoursRef.current.value.toLowerCase() == 'true'),
                    showTrends: this.showTrendsRef.current.value,
                    alarmRangeHi: this.alarmRangeHi.current.value,
                    alarmRangeLow: this.alarmRangeLow.current.value,
                    showCritical: (this.showCriticalRef.current.value.toLowerCase() == 'true'), 
                    offset: {
                        operator: this.operatorRef.current.value,
                        value: isNaN(parseInt(this.valueRef.current.value)) ? this.valueRef.current.value : parseFloat(this.valueRef.current.value)  
                    },
					dataTagId: this.dataTagId.current.value,
					servicedueRangeLow: this.servicedueRangeLow.current.value,
					servicedueRangeHi: this.servicedueRangeHi.current.value,
					showEnergy: this.showEnergyRef.current.value,
					//validRangeLow: this.validRangeLow.current.value,
					//validRangeHi: this.validRangeHi.current.value,
                }
                await postTags(tagDetails);
                this.setState({
                    showModal: false,
                    type: "add"
                }, async () => {
                    await this.doAfterPostData(this.context.state.compressorId);
                    alert("Tag Created Successfully");
                    await this.getTags(this.context.state.compressorId);
                });
            } else {
                await deleteTags(this.state.tagId);
                this.setState({
                    showModal: false,
                    type: "add",
                    tagId: ""
                }, async () => {
                    await this.doAfterPostData(this.context.state.compressorId);
                    alert("Tag Deleted Successfully");
                    await this.getTags(this.context.state.compressorId);
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const tableColDef = [{
            dataField: 'name',
            text: 'Tag Name'
        }, {
            dataField: 'id',
            text: '',
            formatter: this.editFormatter
        }, {
            dataField: 'id',
            text: '',
            formatter: this.deleteFormatter
        }];

        return (
            
            <div className="width_100">
                <div>
                    <GridContainer> 
                        <GridItem xs={12} sm={12} md={8}>    
                            <h5><b>Tag Info</b></h5>     
                        </GridItem>    
                        <GridItem xs={12} sm={12} md={4}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className="float_rght"
                                onClick={this.openModal}
                            >
                                Add Tag
                        </Button>
                        </GridItem>    
                    </GridContainer>    
                </div> 
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}> 
                            <Table keyField="name"
                                data={this.state.tagData}
                                columns={tableColDef}
                                noDataIndication="No Data Available"/>
                        </GridItem>
                    </GridContainer>
                </div>
                <Modal
                    show={this.state.showModal}
                    onHide={this.hideModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.state.type == 'edit' ? "Edit Tag": (this.state.type == 'add') ? "Add Tag" : "Delete Tag!!!"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>    
                        { this.state.type != 'delete' ?     
                        (<div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Name</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.nameRef}/>
                            </div>
							<label className="col-sm-3 col-form-label">Measure Units</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.measureUnitRef}/>
                            </div>							
                        </div>                       
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Measure Property</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.nameasurePropertyRef}/>
                            </div>
							<label className="col-sm-3 col-form-label">Measure Type</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.measureTypeRef} />
                            </div>
                        </div>   
						<div className="form-group row">
							<label className="col-sm-3 col-form-label">Data Tag ID</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.dataTagId}/>
                            </div>
							<label className="col-sm-3 col-form-label">displayTag</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.displayTagRef}/>
                            </div>
						</div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Offset Operator</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.operatorRef}/>
                            </div>
							<label className="col-sm-3 col-form-label">Offset Value</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.valueRef} />
                            </div>
                        </div>                        
                        <div className="form-group row">                            
							<label className="col-sm-3 col-form-label">Show in report</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.showReportRef}/>
                            </div>
							<label className="col-sm-3 col-form-label">Show in trends</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.showTrendsRef}/>
                            </div>
                        </div>                       
                        <div className="form-group row">                            
							<label className="col-sm-3 col-form-label">Show in critical report</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.showCriticalRef}/>
                            </div>
							<label className="col-sm-3 col-form-label">Show in run hours</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.showRunHoursRef}/>
                            </div>
                        </div>                        
                        <div className="form-group row">                            
							<label className="col-sm-3 col-form-label">Alarm Range Low</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.alarmRangeLow}/>
                            </div>
							<label className="col-sm-3 col-form-label">Alarm Range High</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.alarmRangeHi}/>
                            </div>
                        </div>                                               					
						<div className="form-group row">
                            <label className="col-sm-3 col-form-label">Service Due Low</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.servicedueRangeLow}/>
                            </div>
							<label className="col-sm-3 col-form-label">Service Due High</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.servicedueRangeHi}/>
                            </div>
                        </div>  
						<div className="form-group row">
                            <label className="col-sm-3 col-form-label">Show Energy</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.showEnergyRef}/>
                            </div>
							<label className="col-sm-3 col-form-label"></label>
                            <div className="col-sm-3">                               
                            </div>
                        </div>						
						<div className="form-group row" style={{display:"none"}}>
                            <label className="col-sm-3 col-form-label">Valid Range Low</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.validRangeLow}/>
                            </div>
							<label className="col-sm-3 col-form-label">Valid Range High</label>
                            <div className="col-sm-3">
                                <input type="text" className="form-control" ref={this.validRangeHi}/>
                            </div>
                        </div>                        
                        </div>) : <span> Are you sure, you want to delete the tag. </span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="button"
                            variant="contained"
                            className="float_rght"
                            onClick={this.hideModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            className="float_rght"
                            color="primary"
                            onClick={this.postData}
                        >
                            {
                                (this.state.type == "edit") ? "Update" : (this.state.type == "add")
                                 ? "Save" : "Delete"
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    doAfterPostData = async (compressorId) => {
        await invalidateTagsCache(compressorId);
    }
}

ListTag.contextType = TagContext;
export default ListTag;