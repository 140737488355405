import { client, mwClient } from "../index";
import { URL_LOGIN, URL_LOGOUT, URL_SIGNUP } from "../apiconstant";

export const authenticate = async crenditials => {
  return await mwClient.post(URL_LOGIN, crenditials);
};

export const signup = async crenditials => {
  return await client.post(URL_SIGNUP, crenditials);
};

export const logout = async () => {
  return await client.post(URL_LOGOUT);
};
