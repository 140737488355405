import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// context 
import { TagContext } from "../context";

// compressor component
import ListCompressor from "./ListCompressor";
import ListTag from "./ListTag";

class Tags extends React.Component {
  state = {
    compressorId : ''
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  compSelected = (row) => {
    this.setState({
      compressorId: row.id
    });
  }

  render() {
    return (
      <TagContext.Provider value={{ state: this.state, compSelected: this.compSelected }}>
        <div>
          <Card>
            <CardHeader color="primary">
              <h4><b>Tags</b></h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem sm={12} md={6}>
                  <ListCompressor />  
                </GridItem>  
                {(this.state.compressorId != "") ? 
                  (<GridItem sm={12} md={6}>
                    <ListTag compressorId={this.state.compressorId}/>
                  </GridItem>)
                  : (<GridItem sm={12} md={6}>
                    <p className="ver-cen-content">Please select a compressor to list out tags</p>
                  </GridItem>)
                }  
              </GridContainer>  
            </CardBody>  
          </Card>
        </div>
      </TagContext.Provider>
    );
  }
}

Tags.contextType = TagContext;
export default Tags;