import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "react-bootstrap-table-next";
import Button from "components/CustomButtons/Button.jsx";
import Modal from "react-bootstrap/Modal";

// rest
import { getTags, postTags, updateTags, deleteTags } from "../../../rest/tag";

// context
import { TagContext } from "../context";
import { fade } from "@material-ui/core/styles";

import cellEditFactory from 'react-bootstrap-table2-editor';

class ListTag extends React.Component { 
    
    state = {
        tagData: [],        
        type: "edit",
        tagDetails: {}
    }

    constructor(props) {
        super(props);
        this.nameRef = React.createRef();        
        this.alarmRangeLow = React.createRef();
        this.alarmRangeHi = React.createRef();
		this.dataTagId = React.createRef();	
		this.measureTypeRef = React.createRef();
        this.nameasurePropertyRef = React.createRef();
        this.measureUnitRef = React.createRef();
        this.displayTagRef = React.createRef();
        this.operatorRef = React.createRef("");
        this.valueRef = React.createRef();
        this.showReportRef = React.createRef();
        this.showTrendsRef = React.createRef();
        this.showRunHoursRef = React.createRef();
        this.showCriticalRef = React.createRef();        
		this.servicedueRangeLow = React.createRef();
        this.servicedueRangeHi = React.createRef();		
    }

    editFormatter = (cell, row) => {
        return (
            <div className="text-center">
                <span className="fa fa-check" onClick={() => { this.postData(row) }}></span>
            </div>    
        );
    }	
	
	alarmRangeHiFormatter = (cell, row) => {		
        return (
            <div className="text-center">
                <input type="text" className="form-control" style={{width:60}} value={row.alarmRangeHi} />
            </div>    
        );
    }
	
	alarmRangeLowFormatter = (cell, row) => {
        return (
            <div className="text-center">
                <input type="text" className="form-control" style={{width:60}} value={row.alarmRangeLow} />
            </div>    
        );
    }
    
    editTag = (row) => {
        this.setState({            
            type: "edit"
        }, () => {
            this.nameRef.current.value = row.name;            
            this.alarmRangeHi.current.value = (row.alarmRangeHi) ? row.alarmRangeHi : "";
            this.alarmRangeLow.current.value = (row.alarmRangeLow) ? row.alarmRangeLow : "";
			this.dataTagId.current.value = (row.dataTagId) ? row.dataTagId : "";	
			this.measureTypeRef.current.value = row.measureType;
            this.nameasurePropertyRef.current.value = row.measureProperty;
            this.measureUnitRef.current.value = row.measureUnits;
            this.displayTagRef.current.value = row.displayTag;
            this.operatorRef.current.value = (row.offset && row.offset.operator) ? row.offset.operator: "";
            this.valueRef.current.value = (row.offset && row.offset.value) ? row.offset.value : "";
            this.showReportRef.current.value = (row.showReport && row.showReport) ? row.showReport : "";
            this.showRunHoursRef.current.value = (row.showRunHours && row.showRunHours) ? row.showRunHours : "";
            this.showCriticalRef.current.value = (row.showCritical && row.showCritical) ? row.showCritical : "";
            this.showTrendsRef.current.value = (row.showTrends && row.showTrends) ? row.showTrends : "";            
			this.servicedueRangeLow.current.value = (row.servicedueRangeLow) ? row.servicedueRangeLow : "";
			this.servicedueRangeHi.current.value = (row.servicedueRangeHi) ? row.servicedueRangeHi : "";			
            this.context.state.tagId = row.id;
        })
    }
 
    async componentDidMount() {
        await this.getTags(this.context.state.compressorId);
    }

    async componentWillReceiveProps(nxtProps) {
        if (this.props.compressorId != nxtProps.compressorId) {
            this.context.state.compressorId = nxtProps.compressorId;
            await this.getTags(nxtProps.compressorId);
        }
    }

    async getTags(id) {
        try {
            let query = { filter: { "where": { "compressorId": id } } };
            let data = await getTags(query);			
            this.setState({
                tagData: data.data
            })
        } catch (e) {
            console.log(e);
        }   
    }

    postData = async(row) => {
        try {			
			if(row.alarmRangeHi != "" && row.alarmRangeLow != "") {
				/*if(row.alarmRangeLow < -1) {
					alert("Alarm Range Low Value should not be less than -1");
					return;
				}*/
				if(row.alarmRangeHi <= row.alarmRangeLow) {
					alert("Alarm Range High Value should be greater than Alarm Range Low Value");
					return;
				}				
			}
            if (this.state.type == "edit") {				
                let tagDetails = {
                    name: row.name,  
					compressorId: this.context.state.compressorId,
					measureType: (row.measureType) ? row.measureType : "", 
                    measureProperty: (row.measureProperty) ? row.measureProperty : "",  
                    measureUnits: (row.measureUnits) ? row.measureUnits : "",                    
                    displayTag: (row.displayTag) ? row.displayTag : "",  
                    showReport: (row.showReport) ? row.showReport : "",  
                    showRunHours: (row.showRunHours == true) ? true : false,
                    showTrends: (row.showTrends) ? row.showTrends : "",                   
                    showCritical: (row.showCritical == true) ? true : false,  
                    offset: {
                        operator: (row.offset.operator) ? row.offset.operator : "", 
                        value: isNaN(parseInt(row.offset.value)) ? row.offset.value : parseFloat(row.offset.value) 
                    },				
					servicedueRangeLow: (row.servicedueRangeLow) ? row.servicedueRangeLow : "",
					servicedueRangeHi: (row.servicedueRangeHi) ? row.servicedueRangeHi : "",
                    alarmRangeHi: (row.alarmRangeHi) ? row.alarmRangeHi : "",
                    alarmRangeLow: (row.alarmRangeLow) ? row.alarmRangeLow : "",                   
					dataTagId: (row.dataTagId) ? row.dataTagId : "",					
                    id: row.id
                }				
                await updateTags(tagDetails);
                this.setState({                    
                    type: "edit"
                }, async () => {
                    alert("Threshold values are updated successfully");
                    await this.getTags(this.context.state.compressorId); 
                });
            }  
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const tableColDef = [{
            dataField: 'name',
            text: 'Tag Name'
        },{
            dataField: 'alarmRangeHi',
            text: 'High',
			formatter: this.alarmRangeHiFormatter
        },{
            dataField: 'alarmRangeLow',
            text: 'Low',
			formatter: this.alarmRangeLowFormatter
        },{
            dataField: 'id',
            text: '',
            formatter: this.editFormatter,
			editable: (cell, row, rowIndex, colIndex) => {
				return false;
			}
        }];
		
		
        return (
            
            <div className="width_100">
                <div>
                    <GridContainer> 
                        <GridItem xs={12} sm={12} md={8}>    
                            <h5><b>Tag Info</b></h5>     
                        </GridItem>                            
                    </GridContainer>    
                </div> 
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}> 
                            <Table keyField="id"
                                data={this.state.tagData}
                                columns={tableColDef}
                                noDataIndication="No Data Available" cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }/>
                        </GridItem>
                    </GridContainer>
                </div>                
            </div>
        );
    }        
}

ListTag.contextType = TagContext;
export default ListTag;