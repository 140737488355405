import { client, mwClient } from "../index";
import { URL_USER, URL_USERPROFILE } from "../apiconstant";

export const initUser = async crenditials => {
    return await client.post(URL_USER, crenditials);
};

export const getUser = async() => {
    return await client.get(URL_USERPROFILE);
};

export const deleteUser = async(id) => {
    return await client.delete(`${URL_USERPROFILE}\${id}`);
};

export const profileUser = async data => {
    return await client.post(URL_USERPROFILE, data);
};

export const getUserProfile = async data => {
    return await client.get(URL_USERPROFILE, {
        params: {
            filter: data
        }
    });
};

export const updatedUserProfile = async (data, profile_id) => {
    return await client.put(`${URL_USERPROFILE}/${profile_id}`, data);
};

export const rebuildUserMappingCache = async (userId) => {
    return await mwClient.delete(`user/${userId}/cache`);
};