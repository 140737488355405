import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Table from "react-bootstrap-table-next";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from "react-bootstrap/Modal";

// rest
import { getOem } from "../../../rest/oem";
import { getCustomers } from "../../../rest/customer";
import { getCompressorDetails, createCompressor, updateCompressor } from "../../../rest/compressor";

class CustomerDetails extends React.Component {

    state = {
        errMsg: "",
        scsMsg: "",
        oemData: [],
        customerData: [],
        oemId: [],
        customerId: [],
        severity: [],
        codes: {},
        key: "compressor_info",
        sevSelected: "",
        action: "severity",
        showModal: false,
        mdlIdx: 0,
		//binary:false,
	    showTagModal: false,
		severityGeneral: false,
		severityWarning: false,
		severityAlarm: false,
		severityFaults: false,
    }

    constructor(props) {
        super(props);
        this.nameRef = React.createRef();
        this.compressorNameRef = React.createRef();
        this.typeRef = React.createRef();
        this.fabNoRef = React.createRef();
        this.phoneRef = React.createRef();
        this.imeiRef = React.createRef();
        this.statusRef = React.createRef();
        this.activeRef = React.createRef();
        this.serviceDueRef = React.createRef();
        this.serviceDueDateRef = React.createRef();
        this.locationRef = React.createRef();
        this.siteNameRef = React.createRef();
        this.latRef = React.createRef();
        this.longRef = React.createRef();
        this.modalNameRef = React.createRef();
        this.modelValueRef = React.createRef();		
    }

    async componentDidMount() {
        try {
			//debugger;
			let currentBinaryValue = false;
            if (this.props.match.params.compressorId != 0) {
                let res = await getCompressorDetails(this.props.match.params.compressorId);
                this.setState({
                    oemId: [res.data.oemId],
                    customerId: [res.data.customerId]
                })
                this.nameRef.current.value = res.data.name || "";
                this.compressorNameRef.current.value = res.data.compressorName || "";
                this.fabNoRef.current.value = res.data.fabNo || "";
                this.typeRef.current.value = res.data.type || "";
                this.imeiRef.current.value = res.data.imei || "";
                this.phoneRef.current.value = res.data.phone || 0;
                this.statusRef.current.value = res.data.status || "";
                this.activeRef.current.value = res.data.active || "";
                this.serviceDueRef.current.value = res.data.service_due || "";
                this.serviceDueDateRef.current.value = res.data.service_due_date != undefined ? res.data.service_due_date.split(".")[0] : "" || "";
                this.locationRef.current.value = res.data.location || "";
                this.siteNameRef.current.value = res.data.site_name || "";
                this.latRef.current.value = res.data.lat || "";
                this.longRef.current.value = res.data.long || "";
                if (res.data.faultcodes) {
                    let severity = [];
                    let codes = {};					
					Object.keys(res.data.faultcodes).forEach(data => {														
						Object.keys(res.data.faultcodes[data]).forEach(serverityName => {
							severity.push({ id: severity.length + 1, name: serverityName, dataTagId: data, binary: res.data.faultcodes[data][serverityName]["binary"] }); 
							
							let codeArray = [];								
							Object.keys(res.data.faultcodes[data][serverityName].codes).forEach(code => {										
								codeArray.push({faultCode: code, faultMessage: res.data.faultcodes[data][serverityName].codes[code]})
							})										
							codes[severity.length] = codeArray;						
						})																	
                    })						
                    this.setState({
                        severity: severity,
                        codes: codes,
						binary: currentBinaryValue
                    })						
                }
            }
            this.getOemInfo();
            this.getCustomerInfo();
        } catch (e) {
            console.log(e);
        }
    }

    editFormatter = (cell, row, idx, formatExtraData) => {
        return (
            <div className="text-center">
                <span className="fa fa-pencil-square-o" onClick={(e) => { (formatExtraData.type == "fault") ? this.editCode(row, idx) : this.editSeverity(row, idx) }}></span>
            </div>
        );
    }

    deleteFormatter = (cell, row, idx, formatExtraData) => {
        return (
            <div className="text-center">
                <span className="fa fa-trash text-center" onClick={(e) => { (formatExtraData.type == "fault") ? this.deleteCode(cell, idx) : this.deleteSeverity(cell, idx) }}></span>
            </div>
        );
    }

    openModal = (action) => {
        this.setState({
            showModal: true,
            action: action,
            type: "add"
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false
        });
    }
	
	openTagModal = (action) => {
        this.setState({
            showTagModal: true,
            action: action,
            type: "add",
			severityWarning: false,				
			severityAlarm: false,
			severityGeneral: false,
			severityFaults: false
        })
    }

    hideTagModal = () => {
        this.setState({
            showTagModal: false
        });
    }

    editSeverity = (row, idx) => {		
		if(row.name == "Warning") {		
			this.setState({
				severityWarning: true,				
				severityAlarm: false,
				severityGeneral: false,
				severityFaults: false
			})
		}
		if(row.name == "Alarm") {
			this.setState({
				severityAlarm: true,
				severityWarning: false,				
				severityGeneral: false,
				severityFaults: false
			})
		}
		if(row.name == "General") {
			this.setState({
				severityGeneral: true,
				severityWarning: false,
				severityAlarm: false,			
				severityFaults: false
			})
		}
		if(row.name == "Faults") {
			this.setState({
				severityFaults: true,
				severityWarning: false,
				severityAlarm: false,
				severityGeneral: false				
			})
		}
        this.setState({
            showTagModal: true,
            type: "edit",
            action: "severity",
            mdlIdx: idx,
        }, () => {
            //this.modalNameRef.current.value = row.name;
            this.modelValueRef.current.value = row.dataTagId;
        })
    }

    deleteSeverity = (row, idx) => {
        this.setState({
            showTagModal: true,
            type: "delete",
            action: "severity",
            mdlIdx: idx
        })
    }

    editCode = (row, idx) => {
        this.setState({
            showModal: true,
            type: "edit",
            action: "fault",
            mdlIdx: idx,
        }, () => {
            this.modalNameRef.current.value = row.faultCode;
            this.modelValueRef.current.value = row.faultMessage;
        })
    }

    deleteCode = (id, idx) => {
        this.setState({
            showModal: true,
            type: "delete",
            action: "fault",
            mdlIdx: idx
        })
    }

    severitySelected = (row) => {		
        this.setState({
            sevSelected: [row.id]
        })	
    }

    getOemInfo = async () => {
        try {
            let resOem = await getOem();
            this.setState({
                oemData: resOem.data
            })
        } catch (e) {
            console.log(e);
        }
    }

    getCustomerInfo = async () => {
        try {
            let resCustomer = await getCustomers();
            this.setState({
                customerData: resCustomer.data
            })
        } catch (e) {
            console.log(e);
        }
    }

    oemSelected = (row, isSelect, idx, e) => {
        this.setState({
            oemId: [row.id],
            customerId: []
        })
    }

    customerSelected = (row, isSelect, idx, e) => {
        this.setState({
            customerId: [row.id],
            oemId: []
        })
    }

    navToList = () => {
        this.props.history.push(`/admin/compressors`);
    }

    postData = async () => {
		
        try {
            this.setState({
                errMsg: "",
                scsMsg: ""
            })
			
            if (this.validate()) {
                let data = {
                    name: this.nameRef.current.value,
                    compressorName: this.compressorNameRef.current.value,
                    fabNo: this.fabNoRef.current.value,
                    type: this.typeRef.current.value,
                    imei: this.imeiRef.current.value,
                    phone: this.phoneRef.current.value,
                    status: this.statusRef.current.value,
                    active: this.activeRef.current.value,
                    service_due: this.serviceDueRef.current.value,
                    service_due_date: this.serviceDueDateRef.current.value,
                    location: this.locationRef.current.value,
                    site_name: this.siteNameRef.current.value,
                    oemId: this.state.oemId[0],
                    customerId: this.state.customerId[0],
                    lat: this.latRef.current.value,
                    long: this.longRef.current.value
                }
				
                if (this.state.severity.length > 0) {                    		
					let faults = {}					
										
                    /*this.state.severity.forEach(data => {
						let faultData = [];											
                        faultData.push({binary: this.state.binary, dataTagId: data.dataTagId, codes: {}});
                        if (this.state.codes[data.dataTagId] && this.state.codes[data.dataTagId].length > 0) {
                            this.state.codes[data.dataTagId].forEach(codeData => {
                                faultData[0].codes[codeData.faultCode] = codeData.faultMessage;
                            });
                        }
						if(faults[data.name] == undefined) {
							faults[data.name] = faultData;
						}
						else {
							faults[data.name].push(faultData[0]);
						}
                    });*/
					
					this.state.severity.forEach(data => {							
						if(faults[data.dataTagId] == undefined) {
							faults[data.dataTagId] = {};
						}
						faults[data.dataTagId][data.name] = {};						
						faults[data.dataTagId][data.name]["binary"] = data.binary
						faults[data.dataTagId][data.name]["codes"] = {};						
                        if (this.state.codes[data.id] && this.state.codes[data.id].length > 0) {
                            this.state.codes[data.id].forEach(codeData => {
                                faults[data.dataTagId][data.name]["codes"][codeData.faultCode] = codeData.faultMessage;
                            });
                        }												
                    });
					
                    data['faultcodes'] = faults;					
                }				
                if (this.props.match.params.compressorId != 0) {
                    await updateCompressor(this.props.match.params.compressorId, data);
                    this.setState({
                        scsMsg: "Compressor Updated Successfully"
                    })
                } else {
                    await createCompressor(data);
                    this.setState({
                        scsMsg: "Compressor created Successfully"
                    }, () => {
                        this.reset();
                    })
                }
            }
        } catch (e) {
            console.log(e);
            if (e.response) {
                this.setState({ errMsg: e.response.data.error.message });
            }
        }
    }

    validate = () => {
        let eml = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let errMsg;
        if (this.nameRef && this.nameRef.current.value == "") {
            this.nameRef.current.focus();
            errMsg = "Please enter a valid dealer name";
        } else if (this.state.customerId.length == 0 && this.state.oemId.length == 0) {
            errMsg = "Please select a oem / a customer";
        }
        if (errMsg) {
            this.setState({ errMsg: errMsg });
            return false;
        }
        return true;
    }

    validateModal = (action) => {
		if (action != "severity") {
			if (this.modalNameRef && this.modalNameRef.current.value == "") {
				this.modalNameRef.current.focus();
				alert("please enter a valid value");
				return false;
			} 
		}
		if (this.modelValueRef && this.modelValueRef.current.value == "") {
            this.modelValueRef.current.focus();
            alert("please enter a valid value");
            return false;
        };
        return true;
    }

    postModalData = async () => {
        try {
			
            if (this.state.type != "delete" && this.validateModal(this.state.action)) {
                if (this.state.type == "edit") {
                    if (this.state.action == "severity") {
                        let data = [...this.state.severity];
                        let codedata = { ...this.state.codes };
                        //data[this.state.mdlIdx] = { name: this.modalNameRef.current.value, dataTagId: this.modelValueRef.current.value, binary: this.state.binary };						
						if(this.state.severityGeneral == true) {
							data[this.state.mdlIdx] = { id: data[this.state.mdlIdx]["id"], name: 'General', dataTagId: this.modelValueRef.current.value };
						}
						if(this.state.severityWarning == true) {
							data[this.state.mdlIdx] = { id: data[this.state.mdlIdx]["id"], name: 'Warning', dataTagId: this.modelValueRef.current.value };
						}
						if(this.state.severityAlarm == true) {
							data[this.state.mdlIdx] = { id: data[this.state.mdlIdx]["id"], name: 'Alarm', dataTagId: this.modelValueRef.current.value };
						}
						if(this.state.severityFaults == true) {
							data[this.state.mdlIdx] = { id: data[this.state.mdlIdx]["id"], name: 'Faults', dataTagId: this.modelValueRef.current.value };
						}
                        /*if (this.modalNameRef.current.value != this.state.sevSelected) {
                            let updatedata = codedata[this.state.sevSelected]
                            delete codedata[this.state.sevSelected];
                            codedata[this.modalNameRef.current.value] = updatedata;
                        };*/
                        this.setState({
                            severity: data,
                            codes: codedata,
                            sevSelected: "",
                            showTagModal: false,
                            mdlIdx: 0,
                            type: "add",
							severityGeneral: false,
							severityWarning: false,
							severityAlarm: false,
							severityFaults: false
                        });
                    } else {
                        let data = { ...this.state.codes };
                        data[this.state.sevSelected][this.state.mdlIdx] = { faultCode: this.modalNameRef.current.value, faultMessage: this.modelValueRef.current.value };
                        this.setState({
                            codes: data,
                            showModal: false,
                            mdlIdx: 0,
                            type: "add"
                        });
                    }
                } else if (this.state.type == 'add') {
                    if (this.state.action == "severity") {
						//debugger;
                        let data = [...this.state.severity];
                        //data.push({ name: this.modalNameRef.current.value, dataTagId: this.modelValueRef.current.value });
						if(this.state.severityGeneral == true) {
							data.push({ id: data.length + 1, name: 'General', dataTagId: this.modelValueRef.current.value });
						}
						if(this.state.severityWarning == true) {
							data.push({ id: data.length + 1, name: 'Warning', dataTagId: this.modelValueRef.current.value });
						}
						if(this.state.severityAlarm == true) {
							data.push({ id: data.length + 1, name: 'Alarm', dataTagId: this.modelValueRef.current.value });
						}
						if(this.state.severityFaults == true) {
							data.push({ id: data.length + 1, name: 'Faults', dataTagId: this.modelValueRef.current.value });
						}
                        this.setState({
                            severity: data,
                            showTagModal: false,
                            mdlIdx: 0,
                            type: "add",
							severityGeneral: false,
							severityWarning: false,
							severityAlarm: false,
							severityFaults: false
                        })
                    } else {
                        /*let data = { ...this.state.codes };
                        if (!data[this.state.sevSelected]) {
                            data[this.state.sevSelected] = [];
                        };
                        data[this.state.sevSelected].push({ faultCode: this.modalNameRef.current.value, faultMessage: this.modelValueRef.current.value });	*/
						
						let data = {...this.state.codes};						
						let codeArray = [];						
						Object.keys(this.state.codes).forEach(c => {
							if (this.state.codes[c].length > 0) {
								this.state.codes[c].forEach(code => {	
									if(c == this.state.sevSelected) {										
										codeArray.push({ faultCode: code.faultCode, faultMessage: code.faultMessage })
									}
								});
							}
                        })
						codeArray.push({faultCode: this.modalNameRef.current.value, faultMessage: this.modelValueRef.current.value});						
						data[this.state.sevSelected] = codeArray;
						
                        this.setState({
							codes: data,
                            mdlIdx: 0,
                            showModal: false,
                            type: "add"
                        });							
                    }
                } 
            }
			
			 if (this.state.type == 'delete' && this.state.action == "severity") {
                        let codedata = { ...this.state.codes };
                        delete codedata[this.state.sevSelected];
                        let data = [...this.state.severity];
                        data.splice(this.state.mdlIdx, 1);
                        this.setState({
                            codes: codedata,
                            severity: data,
                            mdlIdx: 0,
                            sevSelected: "",
                            showTagModal: false,
                            type: "add"
                        });
                    } else if ((this.state.type == 'delete' && this.state.action != "severity") ){						
                        let data = { ...this.state.codes };
                        data[this.state.sevSelected].splice(this.state.mdlIdx, 1);
                        this.setState({
                            codes: data,
                            mdlIdx: 0,
                            showModal: false,
                            type: "add"
                        });
                    }
        } catch (e) {
            console.log(e);
        }
    }

    reset = () => {
        this.nameRef.current.value = "";
        this.compressorNameRef.current.value = "";
        this.typeRef.current.value = "";
        this.fabNoRef.current.value = "";
        this.imeiRef.current.value = "";
        this.phoneRef.current.value = "";
        this.statusRef.current.value = "";
        this.activeRef.current.value = "";
        this.serviceDueRef.current.value = "";
        this.serviceDueDateRef.current.value = "";
        this.locationRef.current.value = "";
        this.siteNameRef.current.value = "";
        this.latRef.current.value = "";
        this.longRef.current.value = "";
    }
	
	toggleChangeGeneral = () => {			
		this.setState({
			severityGeneral: !this.state.severityGeneral			
		});			
	}
	
	toggleChangeFaults = () => {			
		this.setState({			  
		  severityFaults: !this.state.severityFaults
		});				
	}
	toggleChangeWarning = () => {			
		this.setState({			  
		  severityWarning: !this.state.severityWarning			  
		});				
	}
	toggleChangeAlarm = () => {			
		this.setState({			  
		  severityAlarm: !this.state.severityAlarm			 
		});				
	}
	
	toggleBinary = (rowIndex) => {				
		let severity = [...this.state.severity]
		severity[rowIndex]['binary'] = !severity[rowIndex]['binary']
		this.setState({
			severity: severity
		});
	}

    render() {

        const tableColDef = {
            oem: [{
                dataField: 'id',
                text: 'OEM Id',
            }, {
                dataField: 'name',
                text: 'OEM Name',
            }],
            customer: [{
                dataField: 'id',
                text: 'Customer Id',
            }, {
                dataField: 'name',
                text: 'Customer Name',
            }]
        };

        const selectRowOem = {
            mode: 'radio',
            classes: 'selection-row',
            selectColumnStyle: { width: '40px' },
            clickToSelect: true,
            selected: this.state.oemId,
            onSelect: this.oemSelected
        };

        const selectRowCustomer = {
            mode: 'radio',
            classes: 'selection-row',
            selectColumnStyle: { width: '40px' },
            clickToSelect: true,
            selected: this.state.customerId,
            onSelect: this.customerSelected
        };

        const sevColDef = [{
            dataField: 'dataTagId',
            text: 'Tag Name'
        }, {
            dataField: 'name',
            text: 'Severity'
        }, {
            dataField: 'binary',
            text: 'Binary',
			formatter: (cell, row, rowIndex) => { 				
                return (
                    <input type="checkbox" style={{ marginLeft: 15 }} checked={this.state.severity[rowIndex]['binary'] === true} onChange={() => this.toggleBinary(rowIndex)}></input>
                );
            }
        }, {
            dataField: 'id',
            text: '',
            formatter: this.editFormatter,
            formatExtraData: {
                type: "severity"
            }
        }, {
            dataField: 'id',
            text: '',
            formatter: this.deleteFormatter,
            formatExtraData: {
                type: "severity"
            }
        }];

        const faultColDef = [{
            dataField: 'faultCode',
            text: 'Code'
        }, {
            dataField: 'faultMessage',
            text: 'Message'
        }, {
            dataField: 'id',
            text: '',
            formatter: this.editFormatter,
            formatExtraData: {
                type: "fault"
            }
        }, {
            dataField: 'id',
            text: '',
            formatter: this.deleteFormatter,
            formatExtraData: {
                type: "fault"
            }
        }];

        const sevSelectRowCust = {
            mode: 'radio',
            classes: 'selection-row',
            selectColumnStyle: { width: '40px' },
			selected: this.state.sevSelected,
            onSelect: this.severitySelected
        };

        return (
            <div className="width_100">
                <Card>
                    <CardHeader color="primary">
                        <h4><b>Compressor Info</b></h4>
                    </CardHeader>
                    <CardBody>
                        {((this.state.errMsg && this.state.errMsg !== "") || (this.state.scsMsg && this.state.scsMsg !== "")) ? (
                            <SnackbarContent
                                message={
                                    this.state.errMsg !== ""
                                        ? this.state.errMsg
                                        : this.state.scsMsg !== ""
                                            ? this.state.scsMsg
                                            : ""
                                }
                                color={
                                    this.state.errMsg !== ""
                                        ? "warning"
                                        : this.state.scsMsg !== ""
                                            ? "success"
                                            : ""
                                }
                            />
                        ) : (
                                ""
                            )}
                        <Tabs id="controlled-tab-example" activeKey={this.state.key} onSelect={k => this.setState({ key: k })}>
                            <Tab eventKey="compressor_info" title="Compressor Details">
                                <div style={{padding:'10px'}}>
                                <GridContainer>
                                    <GridItem sm={12} md={7}>
                                        <p></p>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Name</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.nameRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Compressor name</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.compressorNameRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Fab No.</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.fabNoRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Type</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.typeRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">IMEI</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.imeiRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Phone</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.phoneRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Status</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.statusRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Active</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.activeRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Service Due</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.serviceDueRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Service Due Data</label>
                                            <div className="col-sm-8">
                                                <input type="datetime-local" id="test123" className="form-control" ref={this.serviceDueDateRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Location</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.locationRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Site Name</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.siteNameRef} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Geo Coordinates</label>
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" ref={this.latRef} placeholder="Lat" />
                                            </div>
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" ref={this.longRef} placeholder="long" />
                                            </div>
                                        </div>										
                                    </GridItem>
                                    <GridItem sm={12} md={5}>
                                        <div className="accordion" id="accordionExample">
                                            <div className="card">
                                                <div className="card-header" id="headingOne">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            Assign to Oem
                                                    </button>
                                                    </h2>
                                                </div>

                                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        {this.state.oemData.length > 0 ?
                                                            <Table keyField="id"
                                                                selectRow={selectRowOem}
                                                                data={this.state.oemData}
                                                                columns={tableColDef.oem}
                                                                noDataIndication="No Oems Available" />
                                                            :
                                                            <p className="ver-cen-content">No Oems Available</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingTwo">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Assign To Customer
                                                    </button>
                                                    </h2>
                                                </div>
                                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        {this.state.customerData.length > 0 ?
                                                            <Table keyField="id"
                                                                selectRow={selectRowCustomer}
                                                                data={this.state.customerData}
                                                                columns={tableColDef.customer}
                                                                noDataIndication="No Customers Available" />
                                                            :
                                                            <p className="ver-cen-content">No Customers Available</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                </div>                        
                            </Tab>
                            <Tab eventKey="fault_info" title="Fault Info">
                                <div style={{padding:'5px'}}>
                                <GridContainer>
                                    <GridItem sm={12} md={6}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <h5><b>Fault Severity</b></h5>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    className="float_rght"
                                                    onClick={(e) => { this.openTagModal("severity") }}
                                                >
                                                    Add Tag
                                            </Button>
                                            </GridItem>
                                        </GridContainer>
                                        <Table keyField="id"
                                            selectRow={sevSelectRowCust}
                                            data={this.state.severity}
                                            columns={sevColDef}
                                            noDataIndication="No Data Available"
                                            style={{ height: '100px' }} />
                                    </GridItem>
                                    {(this.state.sevSelected != "") ?
                                        (<GridItem sm={12} md={6}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={8}>
                                                    <h5><b>Fault Codes</b></h5>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        color="primary"
                                                        className="float_rght"
                                                        onClick={(e) => { this.openModal("fault") }}
                                                    >
                                                        Add Fault
                                            </Button>
                                                </GridItem>
                                            </GridContainer>
                                            <Table keyField="faultCode"
                                                data={(this.state.codes[this.state.sevSelected]) ? this.state.codes[this.state.sevSelected] : []}
                                                columns={faultColDef}
                                                noDataIndication="No Data Available"
                                                style={{ height: '100px' }} />
                                        </GridItem>)
                                        : (<GridItem sm={12} md={6}>
                                            <p className="ver-cen-content">Please select a severity to list out fault codes</p>
                                        </GridItem>)
                                    }
                                </GridContainer>
                                </div>    
                            </Tab>
                        </Tabs>
                        <GridContainer>
                            <GridItem sm={12} md={12}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="float_rght"
                                    color="primary"
                                    onClick={this.postData.bind(this)}
                                >
                                    {this.props.match.params.compressorId != 0 ? "Update" : "Create"}
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="float_rght"
                                    onClick={this.navToList}
                                >
                                    Cancel
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
				
				<Modal
                    show={this.state.showTagModal}
                    onHide={this.hideTagModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.state.type == 'edit' ? "Edit " : (this.state.type == 'add') ? "Add " : "Delete "}
                            {this.state.action == 'severity' ? "Tag" : "Fault"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.type != 'delete' ?
                            (<div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">{this.state.action == 'severity' ? "Tag Name" : "Fault Code"}</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" ref={this.modelValueRef} />
                                    </div>
                                </div>
								<div className="form-group row">
                                    <div className="col-sm-3"><input type="checkbox" className="form-control" defaultChecked={this.state.severityGeneral} onClick={this.toggleChangeGeneral} style={{width:"10%",float:"left"}} disabled={this.state.type == 'edit' && !this.state.severityGeneral} /><label style={{marginLeft:"10px",float:"left",marginTop:"10px",color:"#000"}}>General</label></div>
									<div className="col-sm-3"><input type="checkbox" className="form-control" defaultChecked={this.state.severityWarning} onClick={this.toggleChangeWarning} style={{width:"10%",float:"left"}} disabled={this.state.type == 'edit' && !this.state.severityWarning} /><label style={{marginLeft:"10px",float:"left",marginTop:"10px",color:"#000"}}>Warning</label></div>
									<div className="col-sm-3"><input type="checkbox" className="form-control" defaultChecked={this.state.severityAlarm} onClick={this.toggleChangeAlarm} style={{width:"10%",float:"left"}} disabled={this.state.type == 'edit' && !this.state.severityAlarm} /><label style={{marginLeft:"10px",float:"left",marginTop:"10px",color:"#000"}}>Alarm</label></div>
									<div className="col-sm-3"><input type="checkbox" className="form-control" defaultChecked={this.state.severityFaults} onClick={this.toggleChangeFaults} style={{width:"10%",float:"left"}} disabled={this.state.type == 'edit' && !this.state.severityFaults} /><label style={{marginLeft:"10px",float:"left",marginTop:"10px",color:"#000"}}>Faults</label></div>
                                </div>                                
                            </div>) : <span> Are you sure, you want to delete the {this.state.action == 'severity' ? "Severity. Deleting severity will delete all the fault assigned to it": "Fault" }. </span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="button"
                            variant="contained"
                            className="float_rght"
                            onClick={this.hideTagModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            className="float_rght"
                            color="primary"
                            onClick={this.postModalData}
                        >
                            {
                                (this.state.type == "edit") ? "Update" : (this.state.type == "add")
                                    ? "Save" : "Delete"
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showModal}
                    onHide={this.hideModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.state.type == 'edit' ? "Edit " : (this.state.type == 'add') ? "Add " : "Delete "}
                            {this.state.action == 'severity' ? "Severity" : "Fault"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.type != 'delete' ?
                            (<div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">{this.state.action == 'severity' ? "Name" : "Fault Code"}</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" ref={this.modalNameRef} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">{this.state.action == 'severity' ? "Tag ID" : "Fault Message"}</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" ref={this.modelValueRef} />
                                    </div>
                                </div>
                            </div>) : <span> Are you sure, you want to delete the {this.state.action == 'severity' ? "Severity. Deleting severity will delete all the fault assigned to it": "Fault" }. </span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="button"
                            variant="contained"
                            className="float_rght"
                            onClick={this.hideModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            className="float_rght"
                            color="primary"
                            onClick={this.postModalData}
                        >
                            {
                                (this.state.type == "edit") ? "Update" : (this.state.type == "add")
                                    ? "Save" : "Delete"
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default CustomerDetails;