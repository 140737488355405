import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "react-bootstrap-table-next";

// rest
import { getCompressor } from "../../../rest/compressor";

// context
import { TagContext } from "../context";

class ListCompressor extends React.Component { 
    
    state = {
        compSelected: []
    }

    async componentDidMount() {
        try {
            this.profileInfo = JSON.parse(window.localStorage.getItem("profileInfo"));
            let query = { filter: { "where": { "customerId": this.profileInfo.customerId } } };
            let res = await getCompressor();

            let data = res.data.filter((cur) => {
                return this.profileInfo.accessItems[0].c != undefined ? this.profileInfo.accessItems[0].c.includes(cur.name) : this.profileInfo.accessItems[0].compressors.includes(cur.name);
            }, this);

            this.setState({
                compData: data
            })
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const tableColDef = [{
            dataField: 'name',
            text: 'Compressor'
        }];

        const selectRowCust = {
            mode: 'radio',
            classes: 'selection-row',
            selectColumnStyle: { width: '40px' },
            onSelect: this.context.compSelected
        };

        return (
            
            <div className="width_100">
                {(this.state.compData) ?
                    <div className="width_100">
                    <h5><b>Compressor Info</b></h5>    
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}> 
                            <Table keyField="name"
                                selectRow={selectRowCust}
                                data={this.state.compData}
                                columns={tableColDef}
                                noDataIndication="No Data Available"
                                    maxHeight={'250px'}
                                style={{ height: '100px' }}/>
                        </GridItem>
                    </GridContainer>
                    </div>
                : ""}
            </div>
        );
    }        
}

ListCompressor.contextType = TagContext;
export default ListCompressor;