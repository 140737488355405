import React from "react";
import {Switch, Route} from 'react-router-dom';

// components
import ListCompressor from "./ListCompressor";
import CompressorDetails from "./CompressorDetails";

class Compressors extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <Route exact path={this.props.match.path} render={(props) => <ListCompressor {...props}/>} />
        <Route path={`${this.props.match.path}/:compressorId`}  render={(props) => <CompressorDetails {...props}/>} />
      </Switch>
    );
  }
}

export default Compressors;