import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from "react-bootstrap-table-next";

// rest
import { getUserProfile, updatedUserProfile} from "../../../rest/users";
import { getCustomers } from "../../../rest/customer";
import { getCompressor } from "../../../rest/compressor";

class ProfileDetails extends React.Component {

    state = {
        errMsg: "",
        custSelected: [],
        compSelected: [],
        disableMapBtn: true,
        key: "profile_info"
    }

    constructor(props){
        super(props);
        this.firstName = React.createRef();
        this.lastName = React.createRef();
        this.phone = React.createRef();
        this.accessLevel = React.createRef();
        this.email = React.createRef();
    }

    async componentDidMount() {
        try{
            if (this.props.email) {
                let res = await getUserProfile({ "where": { "email": this.props.email } }); 
                let profileInfo = res.data.pop();
                this.setState({
                    id: profileInfo.id,
                    custSelected: profileInfo.customerId ? [profileInfo.customerId] : [],
                    compSelected: (profileInfo.accessItems && profileInfo.accessItems.length > 0) ? (profileInfo.accessItems[0].c != undefined ? ( Array.isArray(profileInfo.accessItems[0].c) ? profileInfo.accessItems[0].c: [profileInfo.accessItems[0].c] ) : ( Array.isArray(profileInfo.accessItems[0].compressors) ? profileInfo.accessItems[0].compressors: [profileInfo.accessItems[0].compressors] )) : [],
                    disableMapBtn: (profileInfo.accessItems && profileInfo.accessItems.length > 0 && (profileInfo.accessItems[0].c != undefined ? profileInfo.accessItems[0].c.length : profileInfo.accessItems[0].compressors.length) > 0 ) ? false : true
                })
                console.log(this.state);
                this.firstName.current.value = profileInfo.firstName || "";
                this.lastName.current.value = profileInfo.lastName ||  "";
                this.phone.current.value = profileInfo.phone || "";
                this.accessLevel.current.value = profileInfo.accessLevel || "";
                this.email.current.value = profileInfo.email || "";
                let data = await getCustomers();
                this.setState({
                    customerData: data.data
                });
                if(this.state.custSelected[0]){
                    this.getCompressorInfo(this.state.custSelected[0]);
                }
            }
        } catch (e){
            console.log(e);
        }
    }

    loadCompressor = async(row, isSelect, idx, e) => {
        try {
            this.getCompressorInfo(row.id)
        } catch (e) {
            console.log(e);
        }
    }

    getCompressorInfo = async(id) => {
        try {
            let query = { "where": { "customerId": id}}
            let compressorData = await getCompressor(query);
            this.setState({
                compressorData: compressorData.data,
                custSelected: [id]
            })
            console.log(this.state);
        } catch(e){
            console.log(e);
        } 
    }

    compSlecHandler = async(row, isSelect, idx, e) => {
        try {
            let selectedCompressor = [...this.state.compSelected];
            
            if(!selectedCompressor.includes(row.name)){
                selectedCompressor = [...this.state.compSelected, row.name];
            } else {
                selectedCompressor.splice(selectedCompressor.indexOf(row.name), 1);
            }
            await this.setState({
                compSelected: selectedCompressor,
                disableMapBtn: (selectedCompressor && selectedCompressor.length) > 0 ? false : true
            });
        } catch (e) {
            console.log(e);
        }
    }

    postData = async() => {
        try {
            let profileObj = {
                firstName: this.firstName.current.value || "",
                lastName: this.lastName.current.value || "",
                phone: this.phone.current.value || "",
                email: this.email.current.value || "",
                accessLevel: this.accessLevel.current.value || "User",
                customerId: this.state.custSelected[0],
                accessItems: [{compressors:this.state.compSelected}]
            }
            await updatedUserProfile(profileObj, this.state.id);
            this.props.postedData(this.state.id);
        } catch (e) {
            console.log(e);
            if (e.response) {
                this.setState({ errMsg: e.response.data.error.message });
            }
        }
    }

    reset() {
        this.setState({
            errMsg: ""
        })
    }

    render() {

        const tableColDef = {
            cust:[{
                dataField: 'id',
                text: 'Customer Id'
            },{
                dataField: 'name',
                text: 'Customer Name'
            }],
            comp:[{
                dataField: 'id',
                text: 'Compressor Id'
            },{
                dataField: 'name',
                text: 'Compressor'
            }]
        };

        const selectRowCust = {
            mode: 'radio',
            classes: 'selection-row',
            selectColumnStyle: { width: '40px' },
            clickToSelect: true,
            selected: this.state.custSelected,
            onSelect: this.loadCompressor
        };

        const selectRowComp = {
            mode: 'checkbox',
            classes: 'form-check-input',
            selectColumnStyle: {width: '40px'},
            selected: this.state.compSelected,
            onSelect: this.compSlecHandler.bind(this),
            clickToSelect: true,
            hideSelectAll: true
        };

        return (
            <div className="pad_hor_15">
                {((this.state.errMsg && this.state.errMsg !== "")) ? (
                    <SnackbarContent
                        message={
                            this.state.errMsg !== ""
                                ? this.state.errMsg
                                : ""
                        }
                        color={
                            this.state.errMsg !== ""
                                ? "warning"
                                : ""
                        }
                    />
                ) : (
                    ""
                )}    
                <Tabs id="controlled-tab-example" activeKey={this.state.key} onSelect={k => this.setState({key: k})}>
                    <Tab eventKey="profile_info" title="Profile Details">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>   
                                <div className="p-3">  
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">First Name</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.firstName}/>
                                            </div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Last Name</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.lastName}/>
                                            </div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Phone Number</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" ref={this.phone}/>
                                            </div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Email</label>
                                            <div className="col-sm-8">
                                                <input type="text" disabled className="form-control" ref={this.email}/>
                                            </div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label txt-col-blk">Access Level</label>
                                            <div className="col-sm-8">
                                                <select className="custom-select" ref={this.accessLevel}>
                                                    <option value="User">User</option>    
                                                    <option value="Admin">Admin</option>
                                                    <option value="Super Admin">Super Admin</option>
                                                </select>
                                            </div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={()=> {this.setState({key: "device_mapping"})}}
                                    className="float_rght"
                                >
                                    Next
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="float_rght"
                                    onClick={this.props.hidePopup}
                                >
                                    Cancel
                                </Button>
                            </GridItem>
                        </GridContainer> 
                    </Tab>
                    <Tab eventKey="device_mapping" title="Device Mapping">
                        <div className="width_100 p-3">
                            {(this.state.customerData) ?
                                <div className="width_100">
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}> 
                                       <Table keyField="id"
                                            selectRow={selectRowCust}
                                            data={this.state.customerData}
                                            columns={tableColDef.cust}
                                            noDataIndication="No Data Available"/>
                                            
                                    </GridItem>
                                    {(this.state.compressorData) ? 
                                    <GridItem xs={12} sm={12} md={6}> 
                                        <Table keyField="name"
                                            selectRow={selectRowComp}
                                            data={this.state.compressorData}
                                            columns={tableColDef.comp}
                                            noDataIndication="No Data Available"/>
                                    </GridItem>
                                    :""}
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.disableMapBtn}
                                            onClick={this.postData.bind(this)}
                                            className="float_rght"
                                        >
                                            Map Profile
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            className="float_rght"
                                            onClick={this.props.hidePopup}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            onClick={()=> {this.setState({key: "profile_info"})}}
                                            className="float_rght"
                                        >
                                            Previous
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                </div>
                            : ""}
                        </div>
                    </Tab>
                    <Tab eventKey="customer_mapping" title="Customer Mapping">
                        <div className="width_100 p-3">
                            {(this.state.customerData) ?
                                <div className="width_100">
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}> 
                                       <Table keyField="id"
                                            selectRow={selectRowCust}
                                            data={this.state.customerData}
                                            columns={tableColDef.cust}
                                            noDataIndication="No Data Available"/>
                                            
                                    </GridItem>
                                </GridContainer>
                                </div>
                            : ""}
                        </div>
                    </Tab>
                </Tabs>           
            </div>
        );
    }        
}

export default ProfileDetails;