import { client } from "../index";
import { URL_COMPRESSOR } from "../apiconstant";

export const getCompressor = async (query) => {
    return await client.get(URL_COMPRESSOR, { params: query });
};
  
export const createCompressor = async (data) => {
    return await client.post(URL_COMPRESSOR, data);
};

export const updateCompressor = async (compressor_id, data) => {
    return await client.put(`${URL_COMPRESSOR}/${compressor_id}`, data);
};

export const getCompressorDetails = async (compressor_id) => {
    return await client.get(`${URL_COMPRESSOR}/${compressor_id}`);
};

export const deleteCompressor = async (compressor_id) => {
    return await client.delete(`${URL_COMPRESSOR}/${compressor_id}`);
};