import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "react-bootstrap-table-next";
import Button from "components/CustomButtons/Button.jsx";
import Modal from "react-bootstrap/Modal";

// rest
import {getUser, profileUser, rebuildUserMappingCache} from "../../../rest/users";

// components
import CreateUser from "./CreateUser";
import ProfileDetails from "./ProfileDetails";

class ListUsers extends React.Component {

  state = {
    userData: [],
    showModal: false,
    actionType: "add"
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.fetchUser();
  }

  fetchUser = async() => {
    try{
      let res = await getUser();
      this.setState({
        userData: res.data
      })
    } catch (e){
      console.log(e);
    }
  }

  nameFormatter = (cell, row) => {
    return (
      <span>{cell}</span>
    );
  }

  profileFormatter = (cell, row) => {
    return (
      <div className="text-center"><i className="fa fa-user-o hyperlink" onClick={()=>{ this.openModal(row.email, "profile")}}></i></div>
    );
  }

  addUser = (cell, row) =>{
    this.openModal("", "add");
  }

  openModal = (email, type) => {
    this.setState({
        showModal: true,
        email: email,
        actionType: type
      });
  }

  hideModal = () => {
    this.setState({
      showModal: false,
      actionType: "add",
      email: ""
    });
  }

  postedData = async (email) => {
    try{
      // to create dummy user profile
      let profileObj={
        firstName: email.split("@")[0],
        lastName:"",
        phone:"",
        email:email,
        accessLevel:"User",
        customerId: "",
        accessItem: [{c:[]}]
      }
      await profileUser(profileObj);
      this.setState({
        showModal: false,
        actionType: "add",
        email: ""
      });
      alert("User created");
      await this.fetchUser();
    } catch (e) {
      console.log(e);
    } 
  }

  profiledUser = async(userId)=>{
    try{
      this.setState({
        showModal: false,
        actionType: "add",
        email: ""
      }, () => {
        rebuildUserMappingCache(userId);
      });
      alert("User Profile updated");
      await this.fetchUser();
    } catch (e){
      console.log(e);
    }
  };

  render() {
    const tableColDef = [{
      dataField: 'firstName',
      text: 'First Name',
      formatter: this.nameFormatter
    }, {
      dataField: 'lastName',
      text: 'Last Name',
    }, {
      dataField: 'email',
      text: 'Email',
    }, {
      dataField: 'phone',
      text: 'Phone',
    }, {
      dataField: 'email',
      text: 'Profile',
      formatter: this.profileFormatter
    }];

    return (
      <div>
        <Card>
          <CardHeader color="primary">
            <h4><b>Users</b></h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="float_rght"
                    onClick={this.addUser}
                >
                  <b>Add User</b>
                </Button>
              </GridItem>   
              <GridItem sm={12} md={12}>
                <Table keyField="name"
                  data={this.state.userData}
                  columns={tableColDef}
                  noDataIndication="No Users Available"/>  
              </GridItem>
            </GridContainer>  
          </CardBody>  
        </Card>

        <Modal
          show={this.state.showModal}
          onHide={this.hideModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  {(this.state.actionType == "add") ? "Add User" : "Profile User"}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>    
              {(this.state.actionType == "add")? 
              <CreateUser hidePopup={this.hideModal} postedData={this.postedData} /> : 
              <ProfileDetails email={this.state.email} hidePopup={this.hideModal} postedData={this.profiledUser} />}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ListUsers;