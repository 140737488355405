import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "react-bootstrap-table-next";
import Button from "components/CustomButtons/Button.jsx";
import Modal from "react-bootstrap/Modal";

import { getCustomers, deleteCustomer } from "../../../rest/customer"

class ListCustomers extends React.Component {

  state = {
    customerData: [],
    showModal: false
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.fetchCustomer();
  }

  fetchCustomer = async() => {
    try{
      let res = await getCustomers();
      this.setState({
        customerData: res.data
      })
    } catch (e){
      console.log(e);
    }
  }

  nameFormatter = (cell, row) => {
    return (
      <span className="hyperlink" onClick={() => { this.props.history.push(`/admin/customers/${row.id}`)}}>{cell}</span>
    );
  }

  deleteFormatter = (cell, row) => {
    return (
        <div className="text-center">
            <span className="fa fa-trash text-center" onClick={() => { this.openModal(cell)}}></span>
        </div>    
    );
  }

  navToDetails = () => {
    this.props.history.push(`/admin/customers/0`);
  }

  openModal = (id) => {
    this.setState({
        showModal: true,
        customerId: id
      });
  }

  hideModal = () => {
      this.setState({
          showModal: false
      });
  }

  deleteCustomer = async() => {
    try {
      await deleteCustomer(this.state.customerId);
      this.setState({
          showModal: false,
          customerId: ""
      }, async () => {
          alert("Customer Deleted Successfully");
          await this.fetchCustomer();
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const tableColDef = [{
      dataField: 'name',
      text: 'Name',
      formatter: this.nameFormatter
    }, {
      dataField: 'address',
      text: 'Address',
    }, {
      dataField: 'primaryContact',
      text: 'Primary Contact',
    }, {
      dataField: 'primaryEmail',
      text: 'Email',
    }, {
      dataField: 'primaryPhone',
      text: 'Phone',
    }, {
      dataField: 'id',
      text: '',
      formatter: this.deleteFormatter
    }];

    return (
      <div>
        <Card>
          <CardHeader color="primary">
            <h4><b>Customers</b></h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="float_rght"
                    onClick={this.navToDetails}
                >
                  <b>Add Customers</b>
                </Button>
              </GridItem>   
              <GridItem sm={12} md={12}>
                <Table keyField="name"
                  data={this.state.customerData}
                  columns={tableColDef}
                  noDataIndication="No Customers Available"/>  
              </GridItem>
            </GridContainer>  
          </CardBody>  
        </Card>

        <Modal
          show={this.state.showModal}
          onHide={this.hideModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  Delete Customer!!!
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>    
              <span> Are you sure, you want to delete the customer. </span>
          </Modal.Body>
          <Modal.Footer>
              <Button
                  type="button"
                  variant="contained"
                  className="float_rght"
                  onClick={this.hideModal}
              >
                  Cancel
              </Button>
              <Button
                  type="button"
                  variant="contained"
                  className="float_rght"
                  color="primary"
                  onClick={this.deleteCustomer}
              >
                  Delete
              </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ListCustomers;