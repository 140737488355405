import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "react-bootstrap-table-next";
import Button from "components/CustomButtons/Button.jsx";
import Modal from "react-bootstrap/Modal";

import { getCompressor, deleteCompressor } from "../../../rest/compressor"

class ListCompressor extends React.Component {

  state = {
    compressorData: [],
    showModal: false
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.fetchCompressor();
  }

  fetchCompressor = async() => {
    try{
      let res = await getCompressor();
      this.setState({
        compressorData: res.data
      })
    } catch (e){
      console.log(e);
    }
  }

  nameFormatter = (cell, row) => {
    return (
      <span className="hyperlink" onClick={() => { this.props.history.push(`/admin/compressors/${row.id}`)}}>{cell}</span>
    );
  }

  deleteFormatter = (cell, row) => {
    return (
        <div className="text-center">
            <span className="fa fa-trash text-center" onClick={() => { this.openModal(cell)}}></span>
        </div>    
    );
  }

  navToDetails = () => {
    this.props.history.push(`/admin/compressors/0`);
  }

  openModal = (id) => {
    this.setState({
        showModal: true,
        compressorId: id
      });
  }

  hideModal = () => {
      this.setState({
          showModal: false
      });
  }

  deleteCompressor = async() => {
    try {
      await deleteCompressor(this.state.compressorId);
      this.setState({
          showModal: false,
          compressorId: ""
      }, async () => {
          alert("Compressor Deleted Successfully");
          await this.fetchCompressor();
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const tableColDef = [{
      dataField: 'name',
      text: 'Name',
      formatter: this.nameFormatter
    }, {
      dataField: 'site_name',
      text: 'Site Name',
    }, {
      dataField: 'location',
      text: 'Location',
    }, {
      dataField: 'phone',
      text: 'Phone',
    }, {
      dataField: 'status',
      text: 'Status',
    }, {
      dataField: 'active',
      text: 'Active',
    }, {
      dataField: 'id',
      text: '',
      formatter: this.deleteFormatter
    }];

    return (
      <div>
        <Card>
          <CardHeader color="primary">
            <h4><b>Compressors</b></h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className="float_rght"
                    onClick={this.navToDetails}
                >
                  <b>Add Compressor</b>
                </Button>
              </GridItem>   
              <GridItem sm={12} md={12}>
                <Table keyField="name"
                  data={this.state.compressorData}
                  columns={tableColDef}
                  noDataIndication="No Compressors Available"/>  
              </GridItem>
            </GridContainer>  
          </CardBody>  
        </Card>

        <Modal
          show={this.state.showModal}
          onHide={this.hideModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  Delete Compressor!!!
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>    
              <span> Are you sure, you want to delete the compressor. </span>
          </Modal.Body>
          <Modal.Footer>
              <Button
                  type="button"
                  variant="contained"
                  className="float_rght"
                  onClick={this.hideModal}
              >
                  Cancel
              </Button>
              <Button
                  type="button"
                  variant="contained"
                  className="float_rght"
                  color="primary"
                  onClick={this.deleteCompressor}
              >
                  Delete
              </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ListCompressor;