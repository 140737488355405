import React from "react";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Update from "@material-ui/icons/Update";
import Icon from "@material-ui/core/Icon";

import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";

class DashCards extends React.Component {

    constructor(props) {
        super(props);
    }

    filterCompress = (e, data) =>{
        e.preventDefault();
        console.log(data);
        this.props.filterCompData(data);
    }

    render() {
        return (
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card onClick={(e) =>{this.filterCompress(e, "all")}}>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info" className="icon-container">
                                <Icon className="dash-icon">filter_9_plus</Icon>
                            </CardIcon>
                            <p className={this.props.classes.cardCategory}>Total</p>
                            <h3 className={this.props.classes.cardTitle}>{this.props.details.actualData.length}</h3>
                        </CardHeader>
                        <CardFooter stats>                            
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card onClick={(e) =>{this.filterCompress(e, "active")}}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success" className="icon-container">
                                <Icon className="dash-icon">done</Icon>
                            </CardIcon>
                            <p className={this.props.classes.cardCategory}>Active</p>
                            <h3 className={this.props.classes.cardTitle}>{this.props.details.active}</h3>
                        </CardHeader>
                        <CardFooter stats>                            
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card onClick={(e) =>{this.filterCompress(e, "inactive")}}>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger" className="icon-container">
                                <Icon className="dash-icon">info_outline</Icon>
                            </CardIcon>
                            <p className={this.props.classes.cardCategory}>Inactive</p>
                            <h3 className={this.props.classes.cardTitle}>{this.props.details.inactive}</h3>
                        </CardHeader>
                        <CardFooter stats>                            
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card onClick={(e) =>{this.filterCompress(e, "servicedue")}}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning" className="icon-container">
                                <Icon className="dash-icon">build</Icon>
                            </CardIcon>
                            <p className={this.props.classes.cardCategory}>Service Due</p>
                            <h3 className={this.props.classes.cardTitle}>{this.props.details.servicedue}</h3>
                        </CardHeader>
                        <CardFooter stats>                            
                        </CardFooter>
                    </Card>
                </GridItem>
                {/* <GridItem xs={12} sm={6} md={2}>
                    <Card onClick={(e) =>{this.filterCompress(e, "fault")}}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning" className="icon-container">
                                <Icon className="dash-icon">build</Icon>
                            </CardIcon>
                            <p className={this.props.classes.cardCategory}>Fault</p>
                            <h3 className={this.props.classes.cardTitle}>{this.props.details.fault}</h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={this.props.classes.stats}>
                                <Update />
                                Just Updated
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem> */}
            </GridContainer>
        );
    }
}

export default DashCards;