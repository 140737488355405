/* Development */
/*const prod = {
  META_API_URL: "https://dev.exactspace.co/utvdevapi/",
  DATA_API_URL: "https://exactspace.co/dev/",
  TIMEOUT: 10000
};


const dev = {
  META_API_URL: "https://dev.exactspace.co/utvdevapi/",
  DATA_API_URL: "https://exactspace.co/dev/",
  TIMEOUT: 10000
};*/

/*Production*/
const prod = {
  META_API_URL: "https://dev.exactspace.co/utvapi/",
  DATA_API_URL: "https://exactspace.co/",
  TIMEOUT: 10000
};


const dev = {
  META_API_URL: "https://dev.exactspace.co/utvapi/",
  DATA_API_URL: "https://exactspace.co/",
  TIMEOUT: 10000
};

export const config = process.env.NODE_ENV === "production" ? prod : dev;